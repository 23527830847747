import { useSignal, batch } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { INGFinAppProps } from "../../library/NGFieldExtensions";
import { getClassName, getTestId, getsxObject } from "../../library/utils";
import { useFinApp } from "../../library/finapp";
// import loadFinAppLibrary from "../../library/finapp";

const tag = "NGFinApp";

export default function NGFinApp({ config, context }: INGFinAppProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
      Type: useSignal(config.Type ?? "news"),
      Symbol: useSignal(config.Symbol),
      Exchange: useSignal(config.Exchange),
      XID: useSignal(config.XID),
      XIDs: useSignal(config.XIDs),
      HideElements: useSignal(config.HideElements),
      View: useSignal(config.View),
      EventCount: useSignal(config.EventCount),
      ModelId: useSignal(config.ModelId),
      LimitColumns: useSignal(config.LimitColumns),
      ShowLinks: useSignal(config.ShowLinks),
      RankingType: useSignal(config.RankingType),
    },
    context
  );

  const xid = useSignal(null);

  const handlers = setupHandlers(config, context);

  const onSearch = (data) => {
    batch(() => {
      local.Symbol.value = data.symbol;
      local.Exchange.value = data.exchange.code;
      local.XID.value = data.xid.code;
    });
  };

  const onLinkSymbolClick = (data) => {
    handlers?.["onLinkClick"]?.(new Event("onLinkClick"), data);
  }

  const finctx = useFinApp(onSearch, onLinkSymbolClick);
  const ComponentTag = `fwc-${local.Type.value}` || "div";

  return (
    <>
      {local.Visible.value && (
        <div
          data-testid={getTestId(config)}
          data-type={config.__typename}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
        >
          <ComponentTag
            class="fwc-app"
            xid={local.XID.value}
            xids={local.XIDs.value}
            symbol={local.Symbol.value}
            exchange={local.Exchange.value}
            hideElements={local.HideElements.value} // "basic-quote,bid-ask,mind-events"
            {...(local.ModelId.value ? { modelId: local.ModelId.value } : {})}
            {...(local.View.value ? { view: local.View.value } : {})}
            {...(local.EventCount.value ? { eventcount: local.EventCount.value } : {})}
            {...(local.LimitColumns.value == true ? { limitColumns: true } : {})}
            {...(local.ShowLinks.value == true ? { showLinks: true } : {})}
            {...(local.RankingType.value ? { rankingType: local.RankingType.value } : {})}
          ></ComponentTag>
        </div>
      )}
    </>
  );
}
