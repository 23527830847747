import { INGVisibleMenuProps, MenuStyle } from "../../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import NGFeatureSet from "../NGFeatureSet/NGFeatureSet";
import { Box, Drawer, IconButton, Popover, Skeleton, Stack } from "@mui/material";
import { Feature, VisibleMenu } from "../../../resolvers-types";
import { getsxObject, getTestId } from "../../library/utils";
import { setupLocalState } from "../../library/dataService";
import { computed, signal, useSignal } from "@preact/signals-react";
import MenuIcon from "@mui/icons-material/Menu";
import { useRef } from "react";
import { getTypename } from "../../library/metadataUtils";

export default function NGVisibleMenu({
  config,
  context,
  handleClose = () => null,
  menuStyle = MenuStyle.Horizontal,
}: INGVisibleMenuProps) {
  const local = setupLocalState(
    config,
    {
      Data: useSignal(config.Data ?? {}),
      Loading: useSignal(config.Loading ?? false),
      Style: useSignal(config.Style ?? {}),
      ButtonStyle: useSignal(config.ButtonStyle ?? {}),
    },
    context
  );

  const testid = getTestId(local.Data.value);
  const dataType = getTypename(local.Data.value);

  const open = useSignal(false);

  const anchorElSignal = useSignal<HTMLElement | null>(null);

  const appBarRef = useRef<HTMLHeadingElement>(null);

  //const isOpen = computed(() => Boolean(anchorElSignal.value));

  const handleCloseDo = () => {
    open.value = false;
    anchorElSignal.value = null;

    if (!isNil(handleClose)) handleClose();
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    open.value = true;
    anchorElSignal.value = event.currentTarget;
  };

  if (isNil(local.Data.value?.Features) || local.Data.value?.Features.length === 0) {
    // log.error("No features found in menu", config);
    return <div>NO MENU DATA</div>;
  }
  if (local.Loading.value) return <Skeleton variant="rectangular" width={800} height={40} />;

  return (
    <div data-testid={`${testid}-wrapper`} data-type={`${dataType}-wrapper`}>
      <Box sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}>{localMenu(menuStyle)}</Box>
      <Box sx={{ display: { xs: "flex", sm: "none" } }} ref={appBarRef}>
        <IconButton
          color="inherit"
          size="large"
          onClick={(e) => (open.value === true ? handleCloseDo() : handleOpen(e))}
          sx={{ display: { xs: "flex", sm: "none" }, color: "white" }}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        sx={{ display: { xs: "flex", sm: "none" } }}
        open={open.value}
        anchor="right"
        onClose={handleCloseDo}
        PaperProps={{
          sx: {
            marginTop: `${getBottomPosition(appBarRef.current)}px`,
          },
        }}
      >
        <Box
          sx={{
            width: 250,
            marginBottom: "50px",
            marginTop: "20px",
          }}
        >
          {localMenu(MenuStyle.Vertical)}
        </Box>
      </Drawer>
    </div>
  );

  function getBottomPosition(ref: HTMLElement | null): number {
    if (!ref) return 0; // Handle case where ref is null
    const rect = ref.getBoundingClientRect();
    const a = rect.bottom; // + window.scrollY;
    return a;
  }

  function localMenu(menuStyle) {
    return (
      <Stack
        direction={menuStyle == MenuStyle.Vertical ? "column" : "row"}
        spacing={menuStyle == MenuStyle.Vertical ? 1 : 2}
        sx={getsxObject({ ...local.Style.value, ...(local.Data.value?.Style ?? {}) })}
        data-testid={testid}
        data-type={dataType}
      >
        {(local.Data.value?.Features ?? []).map((feature) => {
          if (isNil(feature)) return null;

          (feature as Feature).ContextId = config.Id;
          (feature as Feature).AllowNavigation = (config as VisibleMenu).AllowNavigation ?? true;
          return (
            <NGFeatureSet
              key={feature?.Id}
              config={{ ...feature, ButtonStyle: config.ButtonStyle } as Feature}
              menuStyle={menuStyle}
              handleClose={handleCloseDo}
              context={context}
            />
          );
        })}
      </Stack>
    );
  }
}
