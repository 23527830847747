import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
// In your JavaScript file
import "@fontsource/inter"; // Defaults to weight 400 with all styles included.

const componentDefaults = {
  MuiStack: {
    defaultProps: {
      useFlexGap: true,
    },
  },
};

export const darkTheme = createTheme({
  styles: {
    ".grey-box": {
      border: "1px solid grey",
      borderRadius: "5px",
      padding: "10px",
    },
  },
  borderVariants: {
    default: {
      border: "solid 1px grey", // default border style
    },
    myCustomBorder: {
      border: "solid 2px blue", // custom border style
    },
    // Define more global custom border variants if needed
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#467DE3",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "var(--md-sys-color-tertiary)",

          "*::-webkit-scrollbar": {
            width: "0.4em",
          },
          "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(var(--md-sys-color-shadow),0.00)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(var(--md-sys-color-shadow),.1)",
            outline: "1px solid slategrey",
          },
        },
      },
    },
  },
  components: {
    ...componentDefaults,
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          color: "white",
          borderColor: "white",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          paddingTop: "10px",
        },
      },
    },
    NGSlider: {
      properties: {
        marks: true,
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          colorSecondary: "white",
          colorPrimary: "white",
          color: "white",
          borderColor: "white",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          colorSecondary: "white",
          colorPrimary: "white",
          color: "white",
          borderColor: "white",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "var(--md-sys-color-tertiary)",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%;
          margin: 0;
          padding: 0;
          background-color: var(--md-sys-color-tertiary);
          color: var(--md-sys-color-on-tertiary);
        }
      `,
    },
    MuiBox: {
      styleOverrides: {
        root: {
          color: "var(--md-sys-color-on-tertiary)",
          backgroundColor: "var(--md-sys-color-tertiary)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "var(--md-sys-color-on-tertiary)",
          backgroundColor: "var(--md-sys-color-tertiary",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: "var(--md-sys-color-on-tertiary)",
          backgroundColor: "var(--md-sys-color-tertiary)",
        },
      },
    },
    MuiStack: {
      styleOverrides: {},
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          color: "var(--md-sys-color-on-tertiary)",
          backgroundColor: "var(--md-sys-color-tertiary)",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "ai-dialog-button" },
          style: {
            borderRadius: "var(--Squarish-Button-Raidus, 32px)",
            backgroundColor: "var(--m-3-sys-light-surface-container-high, var(--md-sys-color-tertiary)",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
          },
        },
      ],
    },
    MuiInputLabel: {
      variants: [
        {
          props: { variant: "editor-title" },
          style: {
            color: "var(--md-sys-color-on-tertiary)",
            backgroundColor: "var(--m-3-sys-light-surface-container-high, var(--md-sys-color-tertiary))",
            "font-feature-settings": "'clig' off, 'liga' off",
            "font-family": "Roboto",
            "font-size": "20px",
            "font-style": "normal",
            "font-weight": "500",
            "line-height": "160%",
            "letter-spacing": "0.15px",
            padding: "10px",
          },
        },
      ],
    },
    // this doesn't work because MUI overrides the variants prop for TextField or Input
    // MuiTextField: {
    //   variants: [
    //     {
    //       props: { customVariant: "ai-dialog-input" },
    //       style: {
    //         color: "var(--app-1-widget-title-text-fill, #42A5F5)",
    //         backgroundColor: "border-radius: var(--borderRadius, 50px)",
    //         border: "0.2px solid var(--app-1-fold-unfold-fill, #42A5F5)",
    //       },
    //     },
    //   ],
    // },
  },
  typography: {
    h2: {
      fontFamily: "Roboto",
      fontStyle: "bold",
      fontWeight: 500,
      fontSize: "26px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      color: "var(--md-sys-color-on-tertiary)",
    },
  },
});

const lightTheme = createTheme({
  cssVariables: { cssVarPrefix: "" },
  palette: {
    primary: {
      main: "#000",
    },
    // background: {
    //   default: "#E0EDFA", // Set default background color to blue
    // },
  },
  components: {
    ...componentDefaults,
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    NGSlider: {
      properties: {
        marks: false,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "10px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiStack: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
        },
      },
    },

    // Name of the component
    MuiFormLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: "var(--md-sys-color-on-surface)",
        },
      },
    },
  },
  typography: {
    fontFamily: "Inter",
    h2: {
      marginTop: "5px",
      marginBottom: "2px",
      fontStyle: "bold",
      fontWeight: 500,
      fontSize: "26px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
    },
  },
});

export default lightTheme;
export const themes = {
  default: lightTheme,
  light: lightTheme,
  dark: darkTheme,
};
