import { Button } from "@mui/material";
import { getClassNameFromString, getsxObject, getTestId } from "../../library/utils";
import { GetCustomLabelForListFromSiteSettings, setupHandlers, setupLocalState } from "../../library/dataService";
import { useSignal } from "@preact/signals-react";
import { getGridContext } from "../../library/metadataUtils";
import { isNil } from "lodash-es";
import { INGListClearAllButtonProps } from "../../library/NGFieldExtensions";
import NGIcon from "../NGIcon/NGIcon";
import { Icon } from "../../../resolvers-types";

export default function NGClearAllButton({ config, context }: INGListClearAllButtonProps) {
  const testId = getTestId(config);

  if (!isNil(config.StartIcon)) {
    config.StartIcon.ContextId = testId;
    config.StartIcon.Id = `${config.Id}_StartIcon`;
  }

  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Disabled: useSignal(config.Disabled ?? false),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );
  const handlers = setupHandlers(config, context);
  const { GridRef } = getGridContext(context);

  if (!local.Visible.value) return null;

  return (
    <Button
      sx={getsxObject(local.Style.value, {
        textTransform: "none",
      })}
      disabled={local.Disabled.value}
      className={`${getClassNameFromString(config.Classes)}`}
      data-testid={testId}
      data-type={config.__typename}
      startIcon={config.StartIcon && <NGIcon config={config.StartIcon as Icon} context={context} />}
      title={GetCustomLabelForListFromSiteSettings("Clear all filters and selections")}
      {...handlers}
      onClick={(event) => {
        GridRef.current?.api.setFilterModel(null);
        GridRef.current?.api.deselectAll();
        if (!isNil(handlers["onClick"])) handlers["onClick"](event);
      }}
    >
      {GetCustomLabelForListFromSiteSettings("Clear Filters")}
    </Button>
  );
}
