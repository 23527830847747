import { INGIconProps } from "../../library/NGFieldExtensions";
import { useSignal } from "@preact/signals-react";
import { setupLocalState } from "../../library/dataService";
import { getClassName } from "../../library/utils";
import { createDynamicMUIIcon } from "../ComponentUtils";
import * as MuiIcons from "@mui/icons-material";
import NGImage from "../NGImage/NGImage";

export default function NGIcon({ config, context }: INGIconProps) {
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Color: useSignal(config.Color),
      Variant: useSignal(config.Variant || "Filled"),
      Style: useSignal(config.Style || {}),
      Classes: useSignal(config.Classes ?? []),
      IconName: useSignal(config.IconName),
      Size: useSignal(config.Size || "medium"),
    },
    context
  );

  /*
    The React.createElement function takes three parameters:
Type: A string or a reference to a class component. This is required.
Props: An optional object containing any props that you want to pass to the element. This can also include children elements.
Children: An optional string or any number of other elements that you want to render as the content of the element.
*/

  switch (config.IconLibrary?.toLocaleLowerCase()) {
    // case "local":
    //   return (
    //     <>
    //       {local.Visible.value && (
    //         <img
    //           data-testid={getTestId(config)}
    //           src={`/src/images/${local.IconName.value}`}
    //           style={{ width: "25px" }}
    //         />
    //       )}
    //     </>
    //   );

    case "asset":
      return (
        <>
          {local.Visible.value && (
            <NGImage
              config={{ ...(config as any), Value: config.IconName, __typename: "Image" }}
              // config={{
              //   Id: config.Id,
              //   Value: local.IconName.value,
              //   Visible: local.Visible.value,
              //   Style: local.Style.value,
              //   Classes: local.Classes.value,
              //   Size: local.Size.value,
              // }}
              context={context}
            />
          )}
        </>
      );
    case "muiicon":
    default:
      return (
        <>
          {local.Visible.value &&
            MuiIcons[local.IconName.value] &&
            createDynamicMUIIcon(
              {
                IconName: local.IconName.value,
                Variant: local.Variant.value,
                Color: local.Color.value,
                Size: local.Size.value,
                Style: local.Style.value,
                ClassName: getClassName(local.Classes),
              },
              config
            )}
        </>
      );
  }
}

// export function createDynamicMUIIcon(local: any) {
//   return React.createElement(MuiIcons[local.IconName.value], {
//     variant: local.Variant.value,
//     color: local.Color.value,
//     fontSize: local.Size.value,
//     sx: getsxObject(local.Style.value),
//   });
// }
