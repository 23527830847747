import { INGChartProps } from "../../library/NGFieldExtensions";
import NGAGChart from "../NGAGChart/NGAGChart";
import NGHighchart from "../NGHighchart/NGHighchart";

export default function NGChart({ config, context }: INGChartProps) {
  switch (config.ChartLibrary) {
    case "Highcharts":
      return <NGHighchart config={config} context={context} />;
    default:
      return <NGAGChart config={config} context={context} />;
  }
}
