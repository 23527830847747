import { Autocomplete, Checkbox, Chip, InputAdornment, TextField } from "@mui/material";
import { useComputed, useSignal, useSignalEffect, batch } from "@preact/signals-react";
import { debounce, isArray, isNil, isObject, isString } from "lodash-es";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { INGMultiSelectProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, getsxObject, isNullOrEmpty, getClassName, getCustomLabel } from "../../library/utils";
import NGIcon from "../NGIcon/NGIcon";
import { log } from "../../library/logger";

export default function NGMultiSelect({ config, context }: INGMultiSelectProps) {
  config.DefaultValue = config.DefaultValue ?? ((config.Multiple ? [] : null) as any);

  const tag = "NGMultiSelect";

  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Disabled: useSignal(config.Disabled ?? false),
      Multiple: useSignal(config.Multiple ?? false),
      FreeSolo: useSignal(config.FreeSolo ?? false),
      DefaultValue: useSignal(config.DefaultValue),
      Value: useSignal(config.DefaultValue),
      MultiSelectPossibleValues: useSignal(config.MultiSelectPossibleValues ?? []),
      Placeholder: useSignal(config.Placeholder ?? ""),
      SearchFilter: useSignal(config.SearchFilter ?? ""),
      Classes: useSignal(config.Classes ?? []),
      Style: useSignal(config.Style ?? {}),
      NoOptionsText: useSignal(config.NoOptionsText ?? "No Options"),
      ClearAfterSelect: useSignal(config.ClearAfterSelect ?? false),
    },
    context
  );
  const handlers = setupHandlers(config, context);

  useSignalEffect(() => {
    if (!config.AutoselectFirstOption) return;

    if (isNil(local.Value.value) && local.MultiSelectPossibleValues.value.length > 0) {
      //local.Value.value = local.MultiSelectPossibleValues.value[0];
      onChangeDo(null, local.MultiSelectPossibleValues.value[0], true);
    }
  });

  const selected = useComputed(() => {
    log.debug(tag, "ENTER useComputed with local.Value.value:", local.Value.value);

    if (!isArray(local.MultiSelectPossibleValues.value)) {
      log.error(tag, "MultiSelectPossibleValues is not an array", local.MultiSelectPossibleValues.value);
      return [];
    }

    const vex = config.ValueExpression;
    const lex = config.LabelExpression;
    const v = local.Multiple.value && isString(local.Value.value) ? local.Value.value.split(",") : local.Value.value;
    const values = local.MultiSelectPossibleValues.value;

    let r = null;
    if (isNil(v)) {
      r = null;
    } else {
      if (vex) {
        if (local.Multiple.value) {
          // If multiple values are expected, process v as an array.
          if (config.FreeSolo) {
            r = v.map((x) => {
              if (lex) return isObject(x) ? x[lex] : x;

              return isObject(x) ? x[vex] : x;
            });
          } else {
            r = values.filter((x) => v.includes(isObject(x) ? x[vex] : x));
          }
        } else {
          if (config.FreeSolo && !values.length) r = v;
          else r = values.find((x) => (isObject(x) ? x[vex] : x) == v);
        }
      } else {
        r = v;
      }
    }
    log.debug(tag, "EXIT useComputed", r, v, local.Value.value);
    return r ?? (local.Multiple.value ? [] : config.FreeSolo ? "" : null);
  });

  const inputProps: any = {};
  if (!isNil(config.Adornment)) {
    const p = config.Adornment.Position?.toLowerCase() ?? "start";

    inputProps[`${p}Adornment`] = (
      <InputAdornment position={p as any}>
        <NGIcon config={{ IconName: "Search" }} context={context} />
      </InputAdornment>
    );
  }

  const handleInputChange = debounce((event, newInputValue) => {
    // if (isNullOrEmpty(newInputValue)) {
    //   if (local.MultiSelectPossibleValues.value.length > 0) {
    //     local.MultiSelectPossibleValues.value =
    //       config.MultiSelectPossibleValues || [];
    //     console.log(
    //       "Resetting MultiSelectPossibleValues",
    //       local.MultiSelectPossibleValues.value
    //     );
    //   }
    // } else
    local.SearchFilter.value = newInputValue;
  }, 300);

  function isSAYT() {
    if (!isNil(config.Bindings) && !isNullOrEmpty(config.Bindings.SearchFilter)) return true;

    return false;
  }

  function isComplexValueType() {
    log.debug(tag, "isComplexValueType", config.ValueType, config.ValueExpression);

    // If the ValueType is not set, but the ValueExpression is set, then it is a complex type
    if (isNil(config.ValueType)) return !isNil(config.ValueExpression);

    return config.ValueType?.toLowerCase() === "object" || config.ValueType?.toLowerCase() === "array";
  }

  //As per MUI documentation, when in SAYT mode, you need to disable the built-in filtering of the Autocomplete component by overriding the filterOptions prop
  const filterOptions = isSAYT() ? (x) => x : undefined;

  const getOptionLabel = (option: any): string => {
    //log.debug(tag, "enter getOptionLabel", option);
    if (isNil(option)) return "";

    const lex = config.OptionsLabelExpression ?? config.LabelExpression;

    const l: string = lex && isObject(option) ? (isNil(option[lex]) ? "" : option[lex]) : option;

    //log.debug(tag, "EXIT getOptionLabel", l, isObject(option));
    return l;
  };
  return (
    <>
      {local.Visible.value && (
        <Autocomplete
          popupIcon={<ExpandMoreIcon />}
          data-type={config.__typename}
          readOnly={context.InDesignMode}
          disabled={local.Disabled.value}
          data-testid={getTestId(config)}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
          multiple={config.Multiple as boolean}
          freeSolo={config.FreeSolo as boolean}
          disableClearable={config.DisableClearable as boolean}
          autoComplete={config.AutoComplete as boolean}
          autoHighlight={config.AutoHighlight as boolean}
          autoSelect={config.AutoSelect as boolean}
          blurOnSelect={config.BlurOnSelect as any}
          options={isArray(local.MultiSelectPossibleValues.value) ? local.MultiSelectPossibleValues.value : []}
          filterOptions={filterOptions as any}
          noOptionsText={local.NoOptionsText.value}
          openOnFocus
          renderInput={(params) => {
            delete (params as any).key;

            return (
              <TextField
                key={params.id}
                {...params}
                label={getCustomLabel(config.Label)}
                placeholder={local.Placeholder.value}
                InputProps={{ ...params.InputProps, ...inputProps }}
              />
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const p = getTagProps({ index });
              delete (p as any).key;

              return (
                <Chip
                  key={index} // Pass the key prop directly
                  {...(p as any)} // Spread the other props
                  label={getOptionLabel(option)}
                />
              );
            })
          }
          isOptionEqualToValue={(option, value) => {
            //log.debug(tag, "enter isOptionEqualToValue", option, value);
            const vex = config.ValueExpression;

            if (value == null) return false;

            const l = vex ? option[vex] === (isObject(value) ? value[vex] : value) : option === value;

            //log.debug(tag, "EXIT isOptionEqualToValue", l);
            return l;
          }}
          getOptionLabel={getOptionLabel}
          value={selected.value}
          // {...handlers}
          onInputChange={(event, newInputValue) => {
            handleInputChange(event, newInputValue);
          }}
          onChange={(e, v) => {
            onChangeDo(e, v);
          }}
          onBlur={(e) => {
            log.debug(tag, "enter onBlur", e, local.Value.value);

            const inputValue = (e.target as any).value;
            if (config.Multiple && !isNullOrEmpty(inputValue)) {
              const event = new KeyboardEvent("keydown", {
                key: "Enter",
                code: "Enter",
                keyCode: 13,
                charCode: 13,
                bubbles: true,
              });

              e.target.dispatchEvent(event);
            }

            if (!isNil((handlers as any).onBlur)) {
              (handlers as any).onBlur(e);
            }
          }}
          disableCloseOnSelect={config.CheckboxMultiSelect as boolean}
          renderOption={
            config.CheckboxMultiSelect
              ? (props, option, { selected }) => {
                  const { key, ...optionProps } = props as any;
                  const lex = config.LabelExpression;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {lex ? option[lex] : option}
                    </li>
                  );
                }
              : undefined
          }
        />
      )}
    </>
  );

  function onChangeDo(e, v: any, stopPrpopagation = false) {
    log.debug(tag, "enter onChange", v, isComplexValueType());

    if (v == null) {
      local.Value.value = v;
    } else {
      // If v is not null or undefined, check the conditions to determine the assignment.
      if (config.ValueExpression) {
        // If config has a truthy ValueExpression, further evaluation is required.
        if (config.Multiple) {
          // If expecting multiple values, process v as an array.
          local.Value.value = (v as any).map((x) =>
            // For each element in the array, if it's an object, extract the value using ValueExpression as the key.
            // Otherwise, return the element as is.
            isObject(x) ? x[config.ValueExpression as string] : x
          );
        } else {
          // If not expecting multiple values, check if v is an object to extract a single value using ValueExpression.
          local.Value.value = isObject(v) ? v[config.ValueExpression] : v;
        }
      } else {
        // If there's no ValueExpression in the config, assign v directly.
        local.Value.value = v;
      }
    }

    if (config.FreeSolo) {
      let isNewValue = true;
      for (let i = 0; i < local.MultiSelectPossibleValues.value.length; i++) {
        if (local.MultiSelectPossibleValues.value[i] === v) {
          isNewValue = false;
        }
      }

      if (isNewValue) {
        log.debug(tag, "A new value has been create in Freesolo mode", v, local.MultiSelectPossibleValues.value);

        if (!stopPrpopagation && !isNil((handlers as any).onNewValue)) {
          (handlers as any).onNewValue(e, v);
        }
      }
    }

    log.debug(tag, "EXIT onChange", v, local.Value.value);
    if (!stopPrpopagation && !isNil((handlers as any).onChange)) {
      (handlers as any).onChange(e, v);
    }

    if (local.ClearAfterSelect.value) {
      log.debug(tag, "Clearing the value after select.  Interval:", config.ClearInterval);
      setTimeout(
        () =>
          batch(() => {
            local.Value.value = null;
            local.SearchFilter.value = "";
          }),
        config.ClearInterval ?? 500
      );
    }
  }
}
