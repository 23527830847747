import { useComputed, useSignal } from "@preact/signals-react";
import { INGChartProps } from "../../library/NGFieldExtensions";
import { setupLocalState } from "../../library/dataService";
import { getTestId } from "../../library/utils";
import { AgCharts } from "ag-charts-react";
import { getTypename } from "../../library/metadataUtils";

export default function NGAGChart({ config, context }: INGChartProps) {
  const local = setupLocalState(
    config,
    {
      Rows: useSignal(config.Rows ?? []), // Rows is the data as passed in by the bindings
      TransposeRowsToSeries: useSignal(config.TransposeRowsToSeries ?? false),
      TransposeRowNames: useSignal(config.TransposeRowNames ?? ""),
      TransposeRowValueFrom: useSignal(config.TransposeRowValueFrom ?? ""),
      TransposeRowLabel: useSignal(config.TransposeRowLabel ?? undefined),
      Loading: useSignal(config.Loading ?? false),
      ChartMetadata: useSignal(config.ChartMetadata ?? {}),
      Visible: useSignal(config.Visible ?? true),
      Colors: useSignal(config.Colors ?? []),
    },
    context
  );

  const options = useComputed(() => {
    const result = { ...local.ChartMetadata.value };
    if (local.Rows.value?.length > 0) {
      result.data = local.Rows.value;
    }
    console.log("chart options", result, local.Rows.value);
    return result;
  });

  return (
    <>
      {local.Loading.value && <div>Loading...</div>}
      {local.Visible.value && (
        <div data-testid={getTestId(config)} data-type={getTypename(config)}>
          <AgCharts options={options.value} />
        </div>
      )}
    </>
  );
}
