export const defaultXPosition = Math.round(window.innerWidth * 0.8) / 2 - 300;

export const propagateTriggers = ["onDeleteComplete", "onSave", "onSaveComplete", "onViewComplete"];

export enum NextActionType {
  OK = "NextCommandIdOnSuccess",
  FAIL = "NextCommandIdOnFailure",
}

export enum Handles {
  START = "startHandle",
  RIGHT = "rightHandle",
  BOTTOM = "bottomHandle",
}

export const defaultTriggers = [
  "onClick",
  "onChange",
  "onBlur",
  "onFocus",
  "onKeyDown",
  "onKeyUp",
  "onSelect",
  "onOpen",
  "onClose",
  "onSubmit",
  "onError",
  "onDrag",
  "onDragEnd",
  "onDragStart",
  "onDrop",
  "onExpand",
  "onCollapse",
  "onMouseEnter",
  "onMouseLeave",
  "onToggle",
];

export const actions = [
  "BecomeUser",
  "CallPhone",
  "CallService",
  "ClearForm",
  "CloseContextMenu",
  "CloseModalPopup",
  "CopyToClipboard",
  "GenerateCode",
  "GoBack",
  "GoToBookmark",
  "GoToPage",
  "Login",
  "Logout",
  "OpenContextMenu",
  "OpenModalPopup",
  "PasteFromClipboard",
  "ReloadPage",
  "SendEmail",
  "SendMessageToChild",
  "SendMessageToParent",
  "SendSms",
  "SetState",
  "Share",
  "ShowMessage",
  "ShowToast",
  "Transform",
  "TriggerAction",
  "ScrollToTop"
];
