import { getUserProfile, updateUserProfileDetails } from "./auth";
import { getState } from "./dataService";
import { log } from "./logger";
import { RuntimeContext } from "./NGFieldExtensions";

const tag = "settingsManager";

export type StorageType = "local" | "session" | "userProfile";
export type FetchUserConfigType = { key: string; group: string; context?: RuntimeContext };
export type UpdateUserConfigType = { key: string; group: string; value: any; context?: RuntimeContext };

function getStorageKey({ group, key }): string {
  return `${group}-${key}`;
}

export function getSessionSetting(group: string, key: string): any | null {
  const storageKey = getStorageKey({ group, key });
  const value = sessionStorage.getItem(storageKey);
  return value ? (JSON.parse(value)) : null;
}

export function setSessionSetting(group: string, key: string, value: any): void {
  const storageKey = getStorageKey({ group, key });
  const serializedValue = JSON.stringify(value);
  sessionStorage.setItem(storageKey, serializedValue);
}

export function removeSessionSetting(group: string, key: string): void {
  const storageKey = getStorageKey({ group, key });
  sessionStorage.removeItem(storageKey);
}

export function getLocalSetting(group: string, key: string): any | null {
  const storageKey = getStorageKey({ group, key });
  const value = localStorage.getItem(storageKey);
  return value ? (JSON.parse(value)) : null;
}

export function setLocalSetting(group: string, key: string, value: any): void {
  const storageKey = getStorageKey({ group, key });
  const serializedValue = JSON.stringify(value);
  localStorage.setItem(storageKey, serializedValue);
}

export function removeLocalSetting(group: string, key: string): void {
  const storageKey = getStorageKey({ group, key });
  localStorage.removeItem(storageKey);
}

export async function getUserProfileSetting(group: string, key: string, context: RuntimeContext): Promise<any | null> {
  return fetchUserProfile({ key, context, group });
}

export async function setUserProfileSetting(group: string, key: string, value: any, context: RuntimeContext): Promise<void> {
  updateUserProfileDetailConfig({ key, value, context, group });
}

async function fetchUserProfile({ context }: FetchUserConfigType): Promise<any | null> {
  try {
    if (!context) return null;
    const user = getState(context).global?.["User"].value || null
    if (!user) return null;
    const profile = await getUserProfile(user.Id, context);
    return profile.data ? profile.data?.Details : {};
  } catch (error) {
    log.error(tag, "error fetching user profile settings", error);
    return null;
  }
}

async function updateUserProfileDetailConfig({ key, group, value, context }: UpdateUserConfigType): Promise<void | null> {
  try {
    if (!context) return null;
    const user = getState(context).global?.["User"].value
    if (!user) return null;
    const details = user?.Details || {};
    const newDetails = { ...details, [getStorageKey({ group, key })]: value };
    await updateUserProfileDetails(user.Id, newDetails, context);
    return;
  } catch (error) {
    log.error(tag, "error setting user profile settings", error);
    return;
  }
}
