import { INGCodeEditorProps } from "../../library/NGFieldExtensions";
import { useComputed, useSignal, useSignalEffect } from "@preact/signals-react";
import { SyntheticEvent, useEffect, useRef } from "react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { Editor, useMonaco, Monaco } from "@monaco-editor/react";
import { debouncedHandler, getTestId, isNullOrEmpty } from "../../library/utils";
import { log } from "../../library/logger";
import NGButton from "../NGButton/NGButton";
import { Button, Dialog, IActionTrigger } from "../../../resolvers-types";
import NGModalPopup from "../NGModalPopup/NGModalPopup";
import { cloneJsonWithIDs } from "../../library/metadataUtils";
import { Box, IconButton } from "@mui/material";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import { isObject, isArray } from "lodash-es";

export default function NGCodeEditor({ config, context }: INGCodeEditorProps) {
  const editorRef = useRef(null);

  const tag = "NGCodeEditor";
  const monaco = useMonaco();

  const local = setupLocalState(
    config,
    {
      DefaultValue: useSignal(config.DefaultValue || ""),
      Value: useSignal(config.DefaultValue || ""),
      Visible: useSignal(config.Visible ?? true),
      Language: useSignal(config.Language ?? "json"),
    },
    context
  );

  const position = useSignal({});
  const stringValue = useSignal("");

  const setModelMarker = (message, length) => {
    const model = editorRef.current?.getModel();
    if (!model || !monaco) return;
    monaco.editor.setModelMarkers(model, 'owner', [
      {
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: length,
        endColumn: 1,
        message,
        severity: monaco.MarkerSeverity.Error,
      },
    ])
  }
  const clearMarkers = () => {
    const model = editorRef.current?.getModel();
    if (!model || !monaco) return;
    monaco.editor.setModelMarkers(model, 'owner', []);
  };
  const validateEditorChange = (value, name) => {
    const SERVICE = 'Service';
    const SERVICES = 'Services';
    const isValueObject = isObject(value);
    const isValueArray = isArray(value);
    if (name === SERVICE) {
      if (!isValueObject || isValueArray) {
        setModelMarker('Invalid input type: Service expected an object', value.length)
        return false;
      } else {
        clearMarkers();
        return true;
      }
    }
    if (name === SERVICES) {
      if (!isValueArray) {
        setModelMarker('Invalid input type: Services expected an array', value.length);
        return false;
      } else {
        clearMarkers();
        return true;
      }
    }
    return true;
  }

  useSignalEffect(() => {
    if (local.Value.value == null) {
      stringValue.value = "";
      return;
    }

    if (local.Language.value == "json") {
      let json = null;
      try {
        json = JSON.parse(editorRef.current.getValue());
      } catch (ex) {
        stringValue.value = JSON.stringify(local.Value.value, null, 2);
        return;
      }

      const o1 = JSON.stringify(json, null, 2);
      const o2 = JSON.stringify(local.Value.value, null, 2);

      if (o1 === o2) return;

      stringValue.value = o2;
      return;
    }

    stringValue.value = local.Value.value;

    // if (!(monaco && editorRef.current)) return;
    // log.debug(tag, "useSignalEffect - Setting position", position.value);
    // window.setTimeout(() => editorRef.current.setPosition(position.value), 100);
  });

  useEffect(() => {
    if (monaco) {
      // Set up custom JSON validation
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        allowComments: false,
      });
    }
  }, [monaco]);

  const handlers = setupHandlers(config, context);

  const handleEditorChange = (value, e) => {
    if (!(monaco && editorRef.current)) return;
    let newValue = value;
    if (local.Language.value === "json" && !isNullOrEmpty(value)) {
      try {
        position.value = editorRef.current.getPosition();
        log.info(tag, "handleEditorChange - Parsing JSON", position.value);
        newValue = JSON.parse(value);
      } catch (ex) {
        return;
      }
    }
    const isValid = validateEditorChange(newValue, config.Name);
    if (!isValid) return;
    local.Value.value = newValue;
    debouncedHandler(handlers["onChange"], e, newValue);

    if (config.OnChange) {
      debouncedHandler(config.OnChange, e, newValue);
    }
  };

  // const dialogId = `${config.Id}-modalPopup`;
  // const button: Button = {
  //   __typename: "Button",
  //   Id: `${config.Id}-fullScreenButton`,
  //   StartIcon: { IconName: "FullscreenExit " },
  //   Actions: [
  //     {
  //       Trigger: "onClick",
  //       preHandler: (handlerName: string, action: IActionTrigger, e: SyntheticEvent, data: object, formCtx: any) => {
  //         console.log("onChange", e, data, formCtx);
  //         local.Visible.value = false;
  //         return true;
  //       },
  //       CommandSet: {
  //         FirstCommandId: "1",
  //         ExecuteCommandsInParallel: false,
  //         Commands: [
  //           {
  //             Id: "1",
  //             Instruction: {
  //               Id: "1",
  //               Name: "OpenModalPopup",
  //             },
  //             Parameters: [{ Name: "ModalPopupId", Value: dialogId }],
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // };

  // const dialog: Dialog = {
  //   __typename: "Dialog",
  //   Id: dialogId,
  //   FullScreen: "true",
  //   ContentContainer: {
  //     __typename: "SimpleContainer",
  //     Id: `${dialogId}-container`,
  //     Items: [
  //       {
  //         ...cloneJsonWithIDs(config),
  //         Id: `${config.Id}-editorInPopup`,
  //       },
  //     ],
  //   },
  // };

  const isFullScreen = useSignal(false);
  const boxRef = useRef<HTMLDivElement>(null);

  const fullScreenStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "background.paper",
    zIndex: 9999,
  };

  const regularStyle = {
    height: config.Height ?? "90vh",
    width: config.Width ?? "90%",
    position: "relative",
  };

  return (
    <>
      {local.Visible.value && (
        <>
          {/* <NGButton config={button} context={context} /> */}
          <Box ref={boxRef} sx={isFullScreen.value ? fullScreenStyle : regularStyle}>
            <IconButton
              onClick={() => (isFullScreen.value = !isFullScreen.value)}
              sx={{ position: "absolute", top: -10, right: 8, zIndex: 10 }}
            >
              {isFullScreen.value ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>

            <Editor
              data-testid={getTestId(config)}
              // height={"100%"}
              // width={"100%"}
              defaultLanguage={local.Language.value}
              defaultValue={stringValue.value}
              value={stringValue.value}
              onMount={(editor) => (editorRef.current = editor)}
              options={config.Options ?? { lineNumbers: "off", glyphMargin: false }}
              onChange={handleEditorChange}
            />
          </Box>
          {/* <NGModalPopup config={dialog} context={context} /> */}
        </>
      )}
    </>
  );
}
