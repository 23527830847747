import { INGStyleEditorProps } from "../../library/NGFieldExtensions";
import { Box, Button, Modal, Paper, Stack, Switch, SwitchProps, TextField, Typography, styled } from "@mui/material";
import CommonStylesEditor from "./NGCommonStylesEditor/NGCommonStylesEditor";
import LayoutEditor from "./NGLayoutEditor";
import TextEditor from "./NGTextEditor";
import NGCodeEditor from "../NGCodeEditor/NGCodeEditor";
import NGConfigurationEditor from "./NGConfigurationEditor";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import {
  doesItSupportConfigurations,
  getChildrenAndState,
  getListOfConfigurationsFromStyle,
  getRootQualifier,
} from "./StyleConfigurationHelper";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { cloneDeep, isEqual, isObject } from "lodash-es";
import CodeIcon from "@mui/icons-material/Code";
import CustomSwitch from "../NGCustomSwitch/NGCustomSwitch";

export default function NGStyleEditor({ config, context }: INGStyleEditorProps) {
  const layoutEditorControls = [
    "Repeater",
    "SimpleContainer",
    // "TabContainer",
    "AccordionContainer",
    "TableContainer",
    "GridContainer",
    "Accordion Group",
  ]

  const textEditorControls = [
    "AIDialog",
    "VisibleMenu",
    "MultiSelect",
    "Button",
    "Checkbox",
    "BasicInput",
    "Label",
    "RadioGroup",
    "Slider",
    "Switch",
    "InputField",
    "Snackbar",
    "List",
    "TreeList",
    "Avatar",
    "FileUpload",
    "AudioPlayer",
    "AudioRecorder",
    "ProportionalBar",
    "FileList",
    "DatePicker",
    "RichTextEditor",
    "AccordionGroup"
  ];

  const local = setupLocalState(
    config,
    {
      Value: useSignal((config as any).Value ?? {}),
      Type: useSignal((config as any).Type ?? null),
      Layout: useSignal((config as any).Layout ?? null),
      StyleClassPopup: useSignal((config as any).StyleClassPopup ?? {}),
    },
    context
  );

  // const typename = context?.Form?.Data.value?.__typename;
  const typename = local.Type.value;
  const layout = local.Layout.value;

  const configurations = getListOfConfigurationsFromStyle(typename, local.Value.value || {});
  const currentConfiguration = useSignal(configurations[0]); //getListOfConfigurationsFromStyle(typename, local.Value.value)[0]

  const subObject = useSignal({});
  const styleClassName = useSignal("");

  const handlers = setupHandlers(config, context);

  useSignalEffect(() => {
    const keys = currentConfiguration.value.Value;
    const newValue = local.Value.value;
    subObject.value = getNestedProperty(newValue, keys) ?? {};
  });

  useSignalEffect(() => {
    const keys = currentConfiguration.peek();
    //const newValue = { ...cloneDeep(local.Value.peek()) }; //, [keys.Value[0]]: subObject.value };

    const o = isObject(local.Value.peek()) ? local.Value.peek() : {};

    const newValueDirty = updateDeepProperty(cloneDeep(o), keys.Value, subObject.value);

    if (isEqual(newValueDirty, local.Value.peek())) {
      return;
    }

    const sanitizedValue = Object.fromEntries(Object.entries(newValueDirty).filter(([_, v]) => !!v));

    local.Value.value = sanitizedValue;

    if (handlers["onChange"]) {
      handlers["onChange"](new Event("onChange"), sanitizedValue);
    }
  });

  const showTextEditor = textEditorControls.includes(typename);
  const showCodeEditor = useSignal(false);
  const showLayoutEditor = layoutEditorControls.includes(typename);

  const handleSaveAsClass = (e) => {
    const qualifiers = [getRootQualifier(typename)];

    if (handlers["onSaveStyleClass"]) {
      handlers["onSaveStyleClass"](e, {
        Name: styleClassName.value,
        Qualifiers: qualifiers,
        Style: local.Value.value,
      });

      styleClassName.value = "";
    }
  };

  const handleSaveClassCloseModal = () => {
    local.StyleClassPopup.value = { Open: false };
    styleClassName.value = "";
  };

  const handleChangeStyleClassName = (e) => {
    styleClassName.value = e.target.value;
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.6rem" }}>
        <CustomSwitch
          checked={showCodeEditor.value}
          onChange={() => {
            showCodeEditor.value = !showCodeEditor.value;
          }}
          icon={<CodeIcon fontSize="small" />}
          checkedIcon={<CodeIcon fontSize="small" />}
          sx={{
            alignSelf: "flex-end",
            marginBottom: "3px",
            position: "absolute",
            top: 0,
            transform: "translateY(75%)",
          }}
          contextid={config?.ContextId}
        />
        {showCodeEditor.value ? (
          <NGCodeEditor config={{ ...config, Height: "200px" }} context={context} />
        ) : (
          <>
            {doesItSupportConfigurations(typename) && (
              <NGConfigurationEditor
                selected={currentConfiguration}
                typename={typename}
                config={config}
                context={context}
                configurations={configurations}
              />
            )}
            {showLayoutEditor && <LayoutEditor selected={subObject} />}
            <CommonStylesEditor selected={subObject} layout={layout} config={config} />
            {showTextEditor && <TextEditor selected={subObject} />}
          </>
        )}
        {config.ShowSaveClass && (
          <>
            <Button onClick={() => (local.StyleClassPopup.value = { Open: true })} className="button-tertiary-large">Save Class</Button>
            <Modal
              open={local.StyleClassPopup.value?.Open ?? false}
              onClose={handleSaveClassCloseModal}
              aria-labelledby="add-new-style-configuration-modal-title"
              aria-describedby="add-new-style-configuration-modal-description"
            >
              <Paper elevation={1} className="style-configuration-add-new-modal">
                <Stack direction="column" alignItems="center" gap="2rem">
                  <Typography id="add-new-style-configuration-modal-title" variant="h6" component="h2">
                    Save current styles as a class
                  </Typography>
                  <TextField
                    placeholder={"Class name"}
                    value={styleClassName.value}
                    onChange={handleChangeStyleClassName}
                  />
                  <Button onClick={handleSaveAsClass} sx={{ width: "200px" }} disabled={!styleClassName.value}>
                    Save
                  </Button>
                </Stack>
              </Paper>
            </Modal>
          </>
        )}
      </Box>
    </>
  );
}

const getNestedProperty = (obj: any, keys: string[]): any => {
  return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
};

type DeepObject = { [key: string]: any };

function updateDeepProperty(obj: DeepObject, path: string[], value: any): DeepObject {
  const [head, ...tail] = path;

  // If the path is empty, return the value
  if (!head) {
    return value;
  }

  // If the property doesn't exist, create it
  if (!Object.prototype.hasOwnProperty.call(obj, head)) {
    obj[head] = {};
  }

  // Recursive case: update or create the nested property
  obj[head] = updateDeepProperty(obj[head], tail, value);

  return obj;
}
