import { useSignal, signal, useSignalEffect, useComputed } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { log } from "../../library/logger";
import { INGMarkdownProps } from "../../library/NGFieldExtensions";
import { getClassName, getTestId, getsxObject, isNullOrEmpty } from "../../library/utils";
import { Box, keyframes } from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import NGIcon from "../NGIcon/NGIcon";

const tag = "NGMarkdown";

export default function NGMarkdown({ config, context }: INGMarkdownProps) {
  log.info(tag, "creating markdown");

  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
      StreamUrl: useSignal(config.StreamUrl ?? null),
      Placeholder: useSignal(config.Placeholder ?? ""),
    },
    context
  );

  const message = useSignal("");
  const isStreaming = useSignal(false);
  const finishedStreaming = useSignal(false);
  const showPlaceholder = useComputed(
    () => local.Placeholder.value && !isNullOrEmpty(local.StreamUrl.value) && isNullOrEmpty(message.value)
  );
  const showLoading = useComputed(
    () =>
      config.TypingIndicatorIcon &&
      config.ShowTypingIndicator &&
      !isNullOrEmpty(local.StreamUrl.value) &&
      !finishedStreaming.value
  );

  const handlers = setupHandlers(config, context);

  useSignalEffect(() => {
    message.value = local.Value.value;
  });

  useSignalEffect(() => {
    if (isNullOrEmpty(local.StreamUrl.value)) return;

    const evtSource = new EventSource(local.StreamUrl.value);
    evtSource.onmessage = (event) => {
      const data = event.data;
      if (data == "[START]") {
        message.value = "";
        isStreaming.value = true;
      } else if (data === "[END]") {
        // Request is closed by our servers once `[END]` is sent,
        // you must close the request otherwise the browser will keep retrying the URL.
        evtSource.close();
        isStreaming.value = false;
        finishedStreaming.value = true;
      } else if (data !== "[START]") {
        message.value += JSON.parse(data).chunk;
      }
    };

    return () => evtSource.close();
  });

  const blinkAnimation = keyframes`
  50% {
    opacity: 0;
  }
`;

  return (
    <>
      {local.Visible.value && (
        <Box
          data-testid={getTestId(config)}
          data-type={config.__typename}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
          {...handlers}
        >
          {showPlaceholder.value && <div>{local.Placeholder.value}</div>}
          <Markdown remarkPlugins={[remarkGfm]}>{message.value}</Markdown>
          {showLoading.value && (
            <NGIcon
              config={{
                ...config.TypingIndicatorIcon,
                Style: {
                  ...config.TypingIndicatorIcon.Style,
                  fontSize: "1rem",
                  // marginLeft: "4px", // Space from text
                  animation: `${blinkAnimation} 1s step-start infinite`,
                },
              }}
              context={context}
            />
          )}
        </Box>
      )}
    </>
  );
}
