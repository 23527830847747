import { log } from "./logger";

export function excelDateFormat(intlDateTimeFormat?: Intl.DateTimeFormatOptions): string {
  const formatter = new Intl.DateTimeFormat("en-US", intlDateTimeFormat);
  const options = formatter.resolvedOptions();
  let formatString = "";

  // Handle date components
  if (options.month === "long") {
    formatString += "mmmm "; // Use "mmmm" for full month names, "mmm" for abbreviated
  } else if (options.month === "short") {
    formatString += "mmm ";
  } else if (options.month === "numeric") {
    formatString += "mm/";
  }

  if (options.day === "numeric" || options.day === "2-digit") {
    formatString += "dd";
  }

  if (options.month === "numeric" || options.month === "2-digit") {
    formatString += "/";
  } else {
    formatString += ", ";
  }

  if (options.year === "numeric" || options.year === "2-digit") {
    formatString += "yyyy";
  }

  if (
    options.hour === "numeric" ||
    options.minute === "numeric" ||
    options.second === "numeric" ||
    options.hour === "2-digit" ||
    options.minute === "2-digit" ||
    options.second === "2-digit"
  ) {
    formatString += " ";
    if (options.hour === "numeric") {
      formatString += "hh";
    }
    if (options.minute === "numeric") {
      formatString += ":mm";
    }
    if (options.second === "numeric") {
      formatString += ":ss";
    }
  }

  // Handle time zones
  if (options.timeZoneName === "short") {
    formatString += " [$-en-US]h:mmAM/PM";
  } else if (options.timeZoneName === "long") {
    formatString += " [$-en-US]h:mmAM/PM;[$-en-US]h:mmAM/PM";
  }

  return formatString;
}

export function excelNumberFormat(intlNumberFormatOptions): string {
  let formatString = "";
  try {
    const style = intlNumberFormatOptions.Style?.toLowerCase();

    const formatter = new Intl.NumberFormat("en-US", {
      style: style,
      currency: intlNumberFormatOptions.Currency ?? (style == "currency" ? "USD" : undefined),
      minimumFractionDigits: intlNumberFormatOptions.MinimumFractionDigits ?? undefined,
      maximumFractionDigits: intlNumberFormatOptions.MaximumFractionDigits ?? undefined,
      signDisplay: intlNumberFormatOptions.SignDisplay ?? undefined,
    });

    const options = formatter.resolvedOptions();

    if (options.style === "currency") {
      // For currency, handle the currency symbol
      const currencySymbol = options.currency || "";
      formatString = `[$${currencySymbol}]$ `;

      if (options.minimumFractionDigits !== undefined) {
        formatString += `0.${"0".repeat(options.minimumFractionDigits)}`;
      } else {
        formatString += "0";
      }
    } else if (options.style === "percent") {
      formatString = "0%";
    } else {
      // Handle decimal format
      if (options.minimumFractionDigits !== undefined) {
        formatString += `0.${"0".repeat(options.minimumFractionDigits)}`;
      } else {
        formatString += "0";
      }

      // Handle grouping (thousands separator)
      if (options.useGrouping === true) {
        formatString = formatString.replace("0", "#,0");
      }
    }
  } catch (ex) {
    log.error("ExcelFormatter", "Error", ex);
  }

  return formatString;
}
