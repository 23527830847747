import { Checkbox } from "../../../resolvers-types";
import { Checkbox as MUICheckbox } from "@mui/material";
import { ReadonlySignal, useComputed, signal, useSignal } from "@preact/signals-react";
import { getTestId, getsxObject, isNullOrEmpty, getClassName } from "../../library/utils";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { isNil } from "lodash";
import { createControlWithLabel, createDynamicMUIIcon } from "../ComponentUtils";
import { INGCheckboxProps } from "../../library/NGFieldExtensions";

export default function NGCheckbox({ config, context }: INGCheckboxProps) {
  //TODO: Handle on change properly and allow for multiple checkboxes
  const local = setupLocalState(
    config,
    {
      //DefaultValue: useSignal(config.DefaultValue || false),
      Value: useSignal(config.Value || null),
      Label: useSignal(config.Label || ''),
      Visible: useSignal(config.Visible ?? true),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const label = useComputed(() => {
    if (local.Value.value === true && !isNullOrEmpty(config.LabelWhenOn)) return config.LabelWhenOn;
    if (!local.Value.value && !isNullOrEmpty(config.LabelWhenOff)) return config.LabelWhenOff;

    return local.Label.value;
  });

  return (
    <>
      {local.Visible.value &&
        createControlWithLabel({
          local,
          handlers,
          config,
          label,
          createControl: createCheckBox,
          className: getClassName(local.Classes),
          labelPlacement: config.LabelPlacement,
        })}
    </>
  );
}

function createCheckBox(local: any, handlers: any, config: Checkbox, className: string, addTestId: boolean) {
  return (
    <MUICheckbox
      data-testid={addTestId ? getTestId(config) : undefined}
      data-type={addTestId ? config.__typename : undefined}
      sx={getsxObject(local.Style.value)}
      className={className}
      onChange={(e) => {
        console.log("~~onChange", e.target.checked);
        local.Value.value = e.target.checked;
        if (!isNil((handlers as any).onChange)) {
          (handlers as any).onChange(e, local.Value.value);
        }
      }}
      icon={
        config.IconWhenUnchecked &&
        createDynamicMUIIcon(
          {
            IconName: config.IconWhenUnchecked.IconName,
            Variant: config.IconWhenUnchecked.Variant,
            Color: config.IconWhenUnchecked.Color,
            Size: config.IconWhenUnchecked.Size as any,
            Style: config.IconWhenUnchecked.Style,
          },
          {
            Id: config.Id,
            ContextId: `${(config as any).ContextId}-unchecked`,
          } as any
        )
      }
      checkedIcon={
        config.IconWhenChecked &&
        createDynamicMUIIcon(
          {
            IconName: config.IconWhenChecked.IconName,
            Variant: config.IconWhenChecked.Variant,
            Color: config.IconWhenChecked.Color,
            Size: config.IconWhenChecked.Size as any,
            Style: config.IconWhenChecked.Style,
          },
          {
            Id: config.Id,
            ContextId: `${(config as any).ContextId}-checked`,
          } as any
        )
      }
      checked={local.Value.value === true}
      color={config.Color as any}
      // defaultChecked={config.DefaultChecked as boolean}
      disabled={config.Disabled as boolean}
      disableRipple={config.DisableRipple as boolean}
      // indeterminate={isNil(local.Value.value)}
      required={config.Required as boolean}
      size={config.Size as any}
    />
  );
}
