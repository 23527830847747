import { generateUID } from "../../library/utils";
import { LayoutItem, List, Maybe } from "../../../resolvers-types";
import NGLayoutItem from "../../generators/NGLayoutItem";
import { getBindings } from "./NGListUtils";
import { RuntimeContext } from "../../library/NGFieldExtensions";
import { CustomCellRendererProps } from "ag-grid-react";

interface CellRendererListProps {
  context: RuntimeContext;
  components?: Maybe<LayoutItem>[] | null;
  cellProps: CustomCellRendererProps;
  config: List;
}

export default function NGCellRendererList({ config, context, components, cellProps }: CellRendererListProps) {
  const index = cellProps.node.sourceRowIndex;
  const field = cellProps.colDef?.field;
  const updatedComponents = components?.map((layoutItem: LayoutItem) => {
    // support for old grid binding 
    if (!layoutItem?.Bindings) {
      layoutItem["Bindings"] = {
        Value: `Row.${field}`,
      };
    }

    return {
      ...layoutItem,
      Id: !index ? layoutItem?.Id : `${layoutItem?.Id}_${index}`
    };
  });

  const CellContainer = {
    __typename: "SimpleContainer",
    Id: `${config.Id}_${field}_${index}_container`,
    Items: updatedComponents,
    Bindings: {
      Data: "Row"
    },
    Style: {
      height: "100%",
      width: "100%",
      padding: "0px",
      flexDirection: "row",
      display: "flex",
      margin: "0px",
      alignItems: "center",
    }
  }

  const { rowContext } = getBindings({
    params: cellProps,
    context,
    config: CellContainer as LayoutItem,
  });

  return <NGLayoutItem config={CellContainer} context={rowContext} />;
}
