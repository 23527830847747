import React, { useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import BrokenImage from "@mui/icons-material/BrokenImage";
import { MUIImageProps } from "./MUIImage.types";

const MUIImage = ({
  src,
  alt = "",
  height = "100%",
  width = "100%",
  duration = 250,
  easing = "cubic-bezier(0.2, 0, 0, 1)", // Standard easing per MD3
  showLoading = false,
  errorIcon = true,
  distance = 0,
  shift = false,
  shiftDuration,
  style = {},
  sx = {},
  bgColor = "transparent",
  fit = "cover",
  position = "relative",
  className,
  wrapperClassName,
  iconWrapperClassName,
  wrapperStyle = {},
  iconWrapperStyle = {},
  onError,
  dataTestId,
  ...otherProps
}: MUIImageProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const shiftValue = typeof distance === "number" ? `${distance}px` : distance;
  const shiftDirections = {
    left: `translateX(-${shiftValue})`,
    right: `translateX(${shiftValue})`,
    top: `translateY(-${shiftValue})`,
    bottom: `translateY(${shiftValue})`,
  };

  const isValidImage = (src: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        setError(false);
        resolve(true);
      };
      image.onerror = () => {
        setError(true);
        resolve(false);
      };
      image.src = src;
    });
  };

  const handleImageLoad = () => {
    setLoaded(true);
    setError(false);
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setError(true);
    setLoaded(false);
    console.log("MUIImage: Failed to load image.");
    if (onError) {
      onError(event);
      console.log("MUIImage: Custom onError handler invoked.");
    }
  };

  useEffect(() => {
    const checkImage = async () => {
      const validImage = await isValidImage(src);
      if (!validImage) {
        setError(true);
      }
    };

    checkImage();
  }, [src]);

  const transformValue = shift && !loaded && typeof shift === "string" ? shiftDirections[shift] || "none" : "none";

  const imageStyles = {
    opacity: loaded ? 1 : 0,
    transition: theme.transitions.create(["opacity", "transform"], {
      duration: shiftDuration || duration,
      easing: easing,
    }),
    transform: transformValue,
    height: "100%",
    width: "100%",
    objectFit: fit,
    ...style,
  };
  return (
    <Box
      className={wrapperClassName}
      position={(theme) => theme.breakpoints.values[position]}
      height={height}
      width={width}
      overflow="hidden"
      sx={{ bgcolor: bgColor, ...wrapperStyle }}
      data-testid={dataTestId}
    >
      {!loaded && showLoading && !error && (
        <Box
          className={iconWrapperClassName}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            ...iconWrapperStyle,
          }}
        >
          {showLoading === true ? <CircularProgress /> : showLoading}
        </Box>
      )}
      {error && errorIcon && (
        <Box
          className={iconWrapperClassName}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            ...iconWrapperStyle,
          }}
        >
          {errorIcon === true ? (
            <BrokenImage sx={{ fontSize: 48, fill: "gray" }} />
          ) : React.isValidElement(errorIcon) ? (
            errorIcon
          ) : null}
        </Box>
      )}
      {!error && (
        <Box
          component="img"
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          className={className}
          sx={{ ...imageStyles, ...sx }}
          {...otherProps}
        />
      )}
    </Box>
  );
};

export { MUIImage };
