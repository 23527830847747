import { INGDatePickerProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { signal, useSignal } from "@preact/signals-react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getClassName, getTestId, getsxObject } from "../../library/utils";

export default function NGDatePicker({ config, context }: INGDatePickerProps) {
  const local = setupLocalState(
    config,
    {
      Disabled: useSignal(config.Disabled ?? false),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Value: useSignal(config.Value ?? null),
      MinDate: useSignal(config.MinDate ?? null),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        data-testid={getTestId(config)}
        data-type={config.__typename}
        className={getClassName(local.Classes)}
        sx={getsxObject(local.Style.value, {
          "& .MuiOutlinedInput-input": {
            fontSize: 12
          }
        })}
        disabled={local.Disabled.value}
        value={local.Value.value ? dayjs(local.Value.value) : null}
        label={config.Label}
        slotProps={{
          field: {
            clearable: config.DisableClearable === true ? false : true,
            onClear: () => (local.Value.value = null),
            "data-testid": getTestId(config),
            "data-type": config.__typename,
          },
          inputAdornment: {
            sx: {
              margin:0,
            }
          }
        }}
        minDate={local.MinDate?.value ? dayjs(local.MinDate.value) : null}
        {...handlers}
        onChange={(newValue) => {
          local.Value.value = newValue
            ? dayjs(newValue).format("YYYY-MM-DD")
            : null;

          if (handlers["onChange"]) handlers["onChange"]("change", local.Value.value);
        }}
      />
    </LocalizationProvider>
  );
}
