import { Location } from "react-router-dom";
import { isArray, isNil, isObject, last } from "lodash-es";
import { generateUID } from "./utils";
import { log } from "./logger";
import { controlLibrary } from "../sampleData/editor-gui/controlLibrary";
import { getBaseComponent } from "../generators/codeGeneration/generationMethods";
import { INGTreeListItems, RuntimeContext } from "./NGFieldExtensions";

export function cloneJsonWithIDs(obj: object, replacements?: { [key: string]: string }, keepers?: object): any {
  let result: any = null;
  const ids: { [key: string]: string } = {};

  try {
    result = cloneJsonNode(obj, replacements, ids);
  } catch (error) {
    console.error("Error cloning JSON node:", error);
  }

  result = replaceRemainingIds(result, ids);
  if (keepers) {
    return { ...result, ...keepers };
  }
  return result;
}

function replaceRemainingIds(component: any, ids: { [key: string]: string }): any {
  let jsonString = JSON.stringify(component ?? {});
  for (const id in ids) {
    jsonString = jsonString.replace(new RegExp(`"${id}"`, "g"), `"${ids[id]}"`);
  }

  return JSON.parse(jsonString ?? "{}");
}

function replaceWithCaseSensitivity(originalText: string, search: string, replace: string): string {
  if (!search || replace === null) return originalText;

  const isFirstLetterUppercase = search[0] === search[0].toUpperCase();
  const adjustedReplace = isFirstLetterUppercase ? replace[0].toUpperCase() + replace.substring(1) : replace;

  return originalText.replace(new RegExp(search, "gi"), adjustedReplace);
}

function cloneJsonNode(
  node: any,
  replacements: { [key: string]: string } | undefined,
  ids: { [key: string]: string },
  propertyName: string | null = null
): any {
  if (isObject(node) && !isArray(node)) {
    const clonedObject: { [key: string]: any } = {};
    for (const property in node) {
      if (property === "Id") {
        const oldId = node[property] as string;
        const newId = ids[oldId] || generateUID();
        ids[oldId] = newId;
        clonedObject[property] = newId;
      } else {
        clonedObject[property] = cloneJsonNode(node[property], replacements, ids, property);
      }
    }
    return clonedObject;
  } else if (isArray(node)) {
    const clonedArray: any[] = [];
    for (const element of node) {
      if (propertyName === "Items" && typeof element === "string") {
        const oldId = element as string;
        const newId = ids[oldId] || generateUID();
        ids[oldId] = newId;
        clonedArray.push(newId);
      } else {
        clonedArray.push(cloneJsonNode(element, replacements, ids, propertyName));
      }
    }
    return clonedArray;
  } else if (typeof node === "string") {
    if (replacements) {
      for (const key in replacements) {
        if (node.toLowerCase().includes(key.toLowerCase())) {
          const newValue = replaceWithCaseSensitivity(node, key, replacements[key]);
          return newValue;
        }
      }
    }
    return node;
  }
  return node;
}

export type ItemOperations = {
  canDrag: boolean;
  canReparent: boolean;
  canReorder: boolean;
  canDelete: boolean;
  canBeParent: boolean;
};

export type ItemNode = {
  Id: string;
  __typename: string | null;
  isHorizontal: boolean;
  level: number;
  parent: string;
  path: number[];
  index: number;
  pathIds: string[];
  config: any;
  context?: RuntimeContext;
  operations: ItemOperations;
};

export function buildInitialDataForTree(data: any) {
  const clonedData = cloneJsonWithIDs(data.Template);
  return {
    ...buildTreeItems(clonedData)[0],
    Template: clonedData,
  };
}

export function buildMetadataMap(
  indexMap: Map<string, ItemNode>,
  node: any,
  parent: any,
  level: number,
  path: number[],
  pathIds: string[],
  key = "Items"
): Map<string, ItemNode> {
  if (node == null) return indexMap;

  // Create and store the current node
  const designNode = createItemNode(node, parent, level, path, pathIds);
  const type = designNode.__typename;
  indexMap.set(node.Id, designNode);

  // If this node can hold children under `key` but doesn't have any array,
  // ensure there's at least an empty array for consistency
  const isComponent = type === "Component";
  const isAccordiongGroup = type === "AccordionGroup";
  const isPage = type === "Page";
  const isMenuOrFeature = type === "Feature" || type === "Menu";
  if ((isMenuOrFeature || isPage) && !node[key]) {
    node[key] = [];
  }

  // 1. Traverse child container if `node` is a Component
  if (isComponent && node.ComponentContainer) {
    buildMetadataMap(
      indexMap,
      node.ComponentContainer,
      node,
      level + 1,
      [...path, 0],
      [...pathIds, node.ComponentContainer.Id],
      key
    );
  }

  // 2. Traverse normal children in `node[key]`
  if (Array.isArray(node[key])) {
    node[key].forEach((child, i) => {
      buildMetadataMap(indexMap, child, node, level + 1, [...path, i], [...pathIds, child?.Id], key);
    });
  }

  if (isAccordiongGroup && node.Header) {
    buildMetadataMap(indexMap, node.Header, node, level + 1, [...path, 0], [...pathIds, node.Header.Id], key);
  }

  // 3. Special handling for AccordionLayout => traverse Groups
  if (type === "AccordionLayout" && Array.isArray(node.Groups)) {
    node.Groups.forEach((group, i) => {
      buildMetadataMap(indexMap, group, node, level + 1, [...path, i], [...pathIds, group?.Id], "Items");
    });
  }

  if (type === "List" && Array.isArray(node.ListColumns)) {
    node.ListColumns.forEach((column, i) => {
      buildMetadataMap(indexMap, column, node, level + 1, [...path, i], [...pathIds, column?.Id], "CellRendererItems");
    });
  }

  if (type === "ListColumn" && Array.isArray(node.CellRendererItems)) {
    node.CellRendererItems.forEach((item, i) => {
      buildMetadataMap(indexMap, item, node, level + 1, [...path, i], [...pathIds, item?.Id], "Items");
    });
  }

  return indexMap;
}

export function createItemNode(
  config: any,
  parent: any,
  level: number,
  path: number[],
  pathIds: string[],
  context?: RuntimeContext
) {
  if (config.Id == null) {
    config.Id = generateUID();
  }

  const type = getTypename(config);

  const itemNode: ItemNode = {
    Id: config.Id,
    __typename: type,
    level: level,
    parent: parent?.Id,
    path,
    pathIds,
    isHorizontal: config.Layout?.Direction == "row" || (config.IgnoreLayout && config.Style?.flexDirection == "row"),
    index: last(path) ?? 0,
    config: config,
    operations: getItemOperations(config, parent),
    context,
  };

  return itemNode;
}

const isAccordionGroup = (node) => node.__typename === "AccordionGroup";

export function getItemOperations(node: any, parent: any): ItemOperations {
  const containersWithRequiredChild = ["Repeater", "TabContainer", "Component", "TabItem"];
  const isRequiredContainer = isContainer(node) && containersWithRequiredChild.includes(parent?.__typename);
  const isRequiredTab = node.__typename == "TabItem" && parent?.__typename == "TabContainer";
  const isTopLevelComponent = node.__typename == "Component" && (parent == null || parent.__typename == "Page");

  const ops: ItemOperations = {
    canDelete: !isRequiredContainer && !isTopLevelComponent && !isColumns(node) && !isToolBar(node),
    canReparent: !isRequiredContainer && !isRequiredTab && !isTopLevelComponent && !isColumns(node) && !isToolBar(node),
    canReorder: !isRequiredContainer && !isTopLevelComponent && !isColumns(node) && !isToolBar(node),
    canBeParent:
      containersWithRequiredChild.includes(node.__typename) ||
      isContainer(node) ||
      isMenuOrFeature(node) ||
      isPage(node) ||
      isAccordionGroup(node) ||
      isColumns(node) ||
      isListColumn(node) ||
      isList(node),
    canDrag: false,
  };

  ops.canDrag = ops.canReorder || ops.canReparent;

  return ops;
}

export function getItemIdentifier(item, type) {
  return formatLabel(item.UniqueName ?? item.Name ?? item.Label ?? item.Value ?? item.Id, type);
}

export function getItemLabel(item, type, root, key) {
  if (root?.__typename === "AccordionGroup") {
    if (key === "Header") return formatLabel(item.Id, "Header");
    if (key === "Items") return formatLabel(item.Id, "Content");
  }
  if (type === "AccordionGroup") {
    return formatLabel(item.Id, "Group");
  }
  if (type === "ListColumn") {
    return formatLabel(item.Name, "Column");
  }
  if (type === "Columns") {
    return formatLabel("", "Columns");
  }

  if (type === "ToolBar") {
    return formatLabel("", "ToolBar");
  }
  const label = item.UniqueName ?? item.Label ?? item.Name ?? item.Value ?? item.Id;
  let node = findParentAndIndex(root, { Id: item.Id });

  if (type === "TabItem") return formatLabel(label, item.Title ?? type);

  if (isReference(node?.item)) return formatLabel(item.ReferenceId, type);

  if (!isContainer(item)) return formatLabel(label, type);
  // handle for first component wrapper
  if (!node) {
    node = { parent: root, index: 0, item };
  }

  if (node && (node as any)?.item && isContainer((node as any)?.item) && !!(node as any)?.item.Style) {
    const isVerticalContainer =
      (node as any)?.item.Style.display == "flex" && (node as any)?.item.Style.flexDirection == "column";
    const isHorizontalContainer =
      (node as any)?.item.Style.display == "flex" && (node as any)?.item.Style.flexDirection == "row";
    const isGridContainer = (node as any)?.item.Style.display == "grid";

    if (isVerticalContainer) return "Vertical Container" + " - " + item.Id;
    if (isHorizontalContainer) return "Horizontal Container" + " - " + item.Id;
    if (isGridContainer) return "Grid Container" + " - " + item.Id;
  }

  return formatLabel(label, type);
}

function formatLabel(label, identifier) {
  if (label == null) return `${identifier}`;
  const result = label?.length > 50 ? label.substring(0, 50) + "..." : label;
  if (!identifier) return result;
  return `${identifier} - ${result}`;
}

export function fixGridMetadata(node: any) {
  if (!node?.CellRendererItems?.length && (node.DisplayAsChip || node.CellLayout)) {
    const itemConfig = node.CellLayout ?? {};
    if (node.DisplayAsChip) {
      itemConfig.DisplayAsChip = node.DisplayAsChip;
      itemConfig.ChipClasses = node.ChipClasses;
      itemConfig.__typename = "Label";
      itemConfig.Id = itemConfig.Id ?? `${node.Id}_Label`;
    }

    node.CellRendererItems = [itemConfig];
  }
}

const getButtons = (item) => {
  const buttons: any[] = [];

  if (item.Visible === false) {
    buttons.push({ IconName: "VisibilityOff" });
  }

  if (item.Actions && item.Actions.length > 0) {
    buttons.push({ IconName: "DoubleArrow" });
  }

  return buttons;
  //   <>
  //     {inNotVisible && (
  //       <NGButton
  //         config={{ TopIcon: { IconName: "VisibilityOff", Id: `map.id{_btn}2` }, Id: `map.id{_btn}` }}
  //         context={{
  //           Path: [],
  //           Repeater: undefined,
  //           Form: undefined,
  //           Handlers: undefined,
  //           InDesignMode: undefined,
  //           DesignProps: undefined,
  //         }}
  //       />
  //     )}
  //     {hasActions && (
  //       <NGButton
  //         config={{ TopIcon: { IconName: "DoubleArrow", Id: `map.id{_btn}3` }, Id: `map.id{_btn}` }}
  //         context={{
  //           Path: [],
  //           Repeater: undefined,
  //           Form: undefined,
  //           Handlers: undefined,
  //           InDesignMode: undefined,
  //           DesignProps: undefined,
  //         }}
  //       />
  //     )}
  //   </>
  // );
};

// Refactored buildTreeItems function with childrenIdentifiers as an array of strings
export function buildTreeItems(node: any, childrenIdentifiers: string[] = ["Items"]): any[] {
  const keys = childrenIdentifiers.length > 0 ? childrenIdentifiers : ["Items"];

  const mapFn = (item: any, parent: any, level: number, index: number, parentNode: any, key: string | null): INode => {
    const ctrl = getLibraryControl(item);
    const type = getTypename(item);

    if (item?.Id == null || !item?.Id) {
      item.Id = generateUID();
    }
    const label = getItemLabel(item, ctrl?.Name ?? type, parent, key);
    return {
      id: item.Id,
      type,
      item,
      label,
      buttons: getButtons(item),
      itemType: "TreeItem",
      icon: getTreeItemIcon(item),
      level,
      parentRestrictions: getNodeParentRestrictions(item),
      childRestrictions: getNodeChildRestrictions(item),
      notAllowedParent: nowAllowedNodeParent(item),
      parent: parentNode,
      index,
      children: [],
      expanded: true,
      operations: getItemOperations(item, parent),
    };
  };

  return node == null ? [] : [mapMetadataRec(node, keys, mapFn, null, 0, 0, null, null)];
}

export function mapMetadataRec(
  node: any,
  keys: string[],
  mapFn: (item: any, parent: any, level: number, index: number, parentNode: any, key: string | null) => any,
  parent: any = null,
  level = 0,
  idx = 0,
  parentNode: any = null,
  nodeKey: string | null = null
): any {
  const newNode = mapFn(node, parent, level, idx, parentNode, nodeKey);

  if (isComponent(node)) {
    if (node.ComponentContainer) {
      // mapMetadataRec
      // children = [{ key: "ComponentContainer", value: node.ComponentContainer }];
      const child = mapMetadataRec(
        node.ComponentContainer,
        keys,
        mapFn,
        node,
        level + 1,
        0,
        newNode,
        "ComponentContainer"
      );
      newNode.children.push(child);
    }
  }

  if (isAccordiongGroup(node)) {
    if (node.Header) {
      const child = mapMetadataRec(node.Header, keys, mapFn, node, level + 1, 0, newNode, "Header");
      newNode.children.push(child);
    }
  }

  if (isList(node)) {
    if (node.ListColumns) {
      const childNode = {
        __typename: "Columns",
        Items: node.ListColumns,
        Id: `${node.Id}_Items`,
      };
      const child = mapMetadataRec(childNode, keys, mapFn, node, level + 1, 0, newNode, "Items");
      newNode.children.push(child);
    }
    if (node.Items) {
      const childNode = {
        __typename: "ToolBar",
        Items: node.Items,
        Id: `${node.Id}_ToolBar`,
      };
      const child = mapMetadataRec(childNode, keys, mapFn, node, level + 1, 0, newNode, "Items");
      newNode.children.push(child);
      return newNode;
    }
  }

  if (isListColumn(node)) {
    node.CellRendererItems ||= [];
    //support for old ListColumn without CellRendererItems
    fixGridMetadata(node);
    // deleting CellLayout as it's fixed to use CellRendererItems
    delete node.CellLayout;

    if (node.CellRendererItems.length > 0) {
      const children = node.CellRendererItems.map((child, index) =>
        mapMetadataRec(child, keys, mapFn, node, level + 1, index, newNode, "Items")
      );
      newNode.children = newNode.children.concat(children);
    }
  }

  // else if (isList(node)) {
  //   if (node.ListColumns) {
  //     children = children.concat(node.ListColumns);
  //   }
  // }
  // else {

  keys.forEach((key: string) => {
    if (Array.isArray(node[key])) {
      const children = node[key].map((child, index) =>
        mapMetadataRec(child, keys, mapFn, node, level + 1, index, newNode, key)
      );
      newNode.children = newNode.children.concat(children);
      // children = children.concat({ key, value: node[key] });
    } else if (node[key] !== undefined && node[key] !== null) {
      const child = mapMetadataRec(node[key], keys, mapFn, node, level + 1, 0, newNode, key);
      newNode.children.push(child);
    }
  });
  // }

  // newNode.children = children.map((child, index) =>
  //   mapMetadataRec(child.value, keys, mapFn, node, level + 1, index, newNode, child.key)
  // );

  return newNode;
}

export function getTreeItemIcon(node: any) {
  if (!node) return null;
  const item = getLibraryControl(node);
  if (!item && isComponent(node)) {
    return getBaseComponent("", null).Icon;
  }
  return item?.Icon;
}

function getLibraryControl(node: any) {
  const typename = getTypename(node);
  return controlLibrary.Components.find((component) =>
    node.Type ? component?.Template?.["Type"] === node.Type : component?.Template?.__typename === typename
  );
}

export function getFilteredTree(data: INGTreeListItems[], searchInput: string): any[] {
  const result = [...data];
  return result.filter((item, idx) => {
    const searchMatchLabel = ((item.label as string) ?? "").toLowerCase().includes(searchInput.toLowerCase());
    const searchMatchType = ((item.type as string) ?? "").toLowerCase().includes(searchInput.toLowerCase());
    if (item.children) {
      const newChildren = getFilteredTree(item.children, searchInput);
      const hasMathcingChildren = newChildren.length > 0;
      result[idx].children = newChildren;
      return hasMathcingChildren || searchMatchLabel || searchMatchType;
    } else return searchMatchLabel || searchMatchType;
  });
}

export function nowAllowedNodeParent(node: any) {
  const restrictionMap = {
    FinApp: "ListColumn",
    Reference: "ListColumn",
    Component: "ListColumn",
    Page: "ListColumn",
    // add more
    default: null,
  };

  return restrictionMap[node.__typename] ?? restrictionMap.default;
}

export function getNodeParentRestrictions(node: { __typename: string }): string | null {
  const restrictionMap = {
    TabItem: "TabContainer",
    Columns: "List",
    ListColumn: "Columns",
    // add more
    default: null,
  };

  return restrictionMap[node.__typename] ?? restrictionMap.default;
}
export function getNodeChildRestrictions(node: { __typename: string }): string | null {
  const restrictionMap = {
    TabContainer: "TabItem",
    Columns: "ListColumn",
    // add more
    default: null,
  };

  return restrictionMap[node.__typename] ?? restrictionMap.default;
}

export function getTypename(control: any): string | null {
  if (isNil(control)) return null;
  return control.__typename ?? control.Typename ?? control.TypeName ?? null;
}

export function getTypenameUrl(control: any): string | null {
  const editorPages = ["component", "page", "feature", "menu", "template"];
  const editor = editorPages.find((path) => pathnameIncludes(path));
  switch (editor) {
    case "component":
      return "Component";
    case "page":
      return "Page";
    case "feature":
      return "Feature";
    case "menu":
      return "Menu";
    case "template":
      return "PageTemplate";
    default:
      return getTypename(control);
  }
}

export const filterEmpty = (obj: any | null) => {
  if (!obj) return false;
  const newObj = Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (isObject(value)) {
        return Object.keys(value).length > 0;
      }
      return value;
    })
  );
  return Object.keys(newObj).length > 0;
};

export function pathnameIncludes(text: string) {
  return window.location.pathname.includes(text);
}

export function isLayout(c: any) {
  return getTypename(c)?.endsWith("Layout") ?? false;
}

export function findParentAndIndex(
  root: any,
  needle: object
): { parent: any | null; index: number | null; item?: any } | null {
  function search(node: any | null, parent, level: number, path: number[]) {
    if (!node) return null;
    const isComponent = getTypename(node) == "Component";
    const items = isComponent ? node.ComponentContainer.Items : node.Items;
    if (isArray(items)) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (Object.entries(needle).every(([k, v]) => item[k] == v)) {
          return {
            parent: isComponent ? node.ComponentContainer : node,
            index: i,
            level,
            path,
            item,
            itemPath: [...path, i],
          };
        }
        const result = search(item, node, level + 1, [...path, i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  return search(root, null, 0, []);
}

export function matchTypename(match: string, config: unknown): boolean {
  if (isNil(match) || isNil(config)) return false;
  return getTypename(config)?.includes(match) ?? false;
}

export function isContainer(config): boolean {
  if (isNil(config)) return false;
  return getTypename(config)?.includes("Container") ?? false;
}

export function isMenuOrFeature(config): boolean {
  if (isNil(config)) return false;
  return isMenu(config) || isFeature(config);
}

export function isMenu(config): boolean {
  if (isNil(config)) return false;
  return getTypename(config)?.includes("Menu") ?? false;
}

export function isFeature(config): boolean {
  if (isNil(config)) return false;
  return getTypename(config)?.includes("Feature") ?? false;
}

export function isComponentGuiRoute(location?: Location<any>) {
  if (isNil(location)) return false;
  return location.pathname === "/ngeditor/component";
}

export function isComponent(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Component";
}

export function isAccordiongGroup(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "AccordionGroup";
}

export function isRepeater(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Repeater";
}

export function isList(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "List";
}

export function isListColumn(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "ListColumn";
}
export function isColumns(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Columns";
}
export function isToolBar(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "ToolBar";
}

export function isTabs(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "TabContainer";
}

export function isPage(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Page";
}

export function isReference(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Reference";
}

export function getItems(config, key = "Items") {
  return config[key] ?? config.ComponentContainer?.[key] ?? config.Content?.[0] ?? [];
}

export function getNodeKey(node: any, targetType?: string | null, sourceType?: string | null) {
  if (isNil(node)) return "Items";
  if (isListColumn(node)) return "CellRendererItems";
  if (isMenuOrFeature(node)) return "Features";
  if (isList(node) && targetType === 'Columns') return "ListColumns";
  if (isList(node) && targetType === 'ListColumn' && sourceType === 'ListColumn') return "ListColumns";
  return "Items";
}

export function getTargetId(data: any) {
  if (data.type === "Columns") return data.parent.id;
  return data.id;
}

export function findParentPropEditorFormId(context) {
  for (let i = context.Path.length - 1; i >= 0; i--) {
    if (context.Path[i - 1].__typename === "PropertiesEditor" && context.Path[i].__typename === "Form")
      return context.Path[i].Id;
  }

  log.error(
    `NG${context.Path[context.Path.length - 1].__typename}`,
    "Error, could not find parent PropertiesEditor Form "
  );

  return null;
}

export function getGridContext(context) {
  for (let i = context.Path.length - 1; i >= 0; i--) {
    if (context.Path[i].__typename === "List") return context.Path[i];
  }

  return null;
}
