import { Box } from '@mui/material';
import React from 'react'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

interface VirtualListProps {
  children: React.ReactNode;
  containerHeight?: number | string;
}

const VirtualList = React.forwardRef<VirtuosoHandle, VirtualListProps>(({ children, containerHeight = "500px" }, ref) => {
  const childrenArray = React.Children.toArray(children);
  const onFollowOutputHandler = (atBottom: boolean) => {
    return atBottom ? "auto" : false;
  };

  const getContainerHeight = () => {
    // if can convert containerHeight into number return it
    const height = Number(containerHeight);
    if (!isNaN(height)) {
      return height;
    }
    return containerHeight;
  }

  return (
    <Box sx={{ height: getContainerHeight(), width: "auto" }}>
      <Virtuoso
        ref={ref}
        followOutput={onFollowOutputHandler}
        style={{ height: "100%", width: "100%" }}
        totalCount={childrenArray.length}
        initialTopMostItemIndex={0}
        itemContent={(index) => (
          <Box key={index}>{childrenArray[index]}</Box>
        )}
      />
    </Box>
  );
});

export default VirtualList;