import { extractInstruction } from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { monitorForExternal } from "@atlaskit/pragmatic-drag-and-drop/external/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { Signal, batch, useSignal, useSignalEffect } from "@preact/signals-react";
import { isNil, keyBy } from "lodash-es";
import { meta, setupHandlers } from "../library/dataService";
import { runNextFrame } from "../library/utils";
import { setupDnd, useDesignerForOverlay } from "../library/designer";
import { Box } from "@mui/material";
import { CSSProperties, forwardRef, useRef } from "react";
import { CleanupFn } from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import NGDropIndicatorBox from "./NGDropIndicatorBox";
import { NGDropIndicatorTreeItem } from "./NGDropIndicatorTreeItem";
import { isComponent, ItemNode } from "../library/metadataUtils";
import { findMeta } from "../library/interpreter";

const tag = "designer";

type BorderOverlay = {
  type: string;
  id: string;
  rect: { left: number; top: number; width: number; height: number };
  style: CSSProperties;
  status: string;
  kind: string;
  node: ItemNode;
};

function createBorderOverlay(target: any, id: any, kind, node: any): BorderOverlay {
  const bounds = target?.getBoundingClientRect();
  const rect = { left: bounds?.left, top: bounds?.top, width: bounds?.width, height: bounds?.height };
  const status = "hovered";

  let style = {};
  switch (kind) {
    case "parent":
      style = {
        border: "dotted 1px var(--editor-select-color)",
        // boxSizing: "border-box",
      };
      // rect = { left: bounds.left - 4, top: bounds.top - 4, width: bounds.width + 4, height: bounds.height + 4 };
      break;
    case "selected":
      style = {
        border: "solid 1px var(--editor-select-color)",
      };
      break;
    case "target":
      style = {
        border: "solid 2px var(--editor-select-color)",
      };
      break;
    case "child":
      style = {
        border: "dotted 1px var(--editor-select-color)",
      };
      break;
    case "drop":
      break;
    case "drag":
      style = {
        background: "white",
        opacity: 0,
      };
      break;
  }

  return {
    type: "border",
    id,
    rect,
    style,
    status,
    kind,
    node,
  };
}

const DropOverlay = ({ id, style, edge, instruction }) => {
  return (
    <Box sx={style}>
      {edge && <NGDropIndicatorBox edge={edge} />}
      {instruction && <NGDropIndicatorTreeItem instruction={instruction} />}
    </Box>
  );
};

const DragOverlay = ({ id, style, rect, node, selected }) => {
  const handleRef = useRef(null);

  const overlayStyle = {
    position: "absolute",
    left: `${rect.left}px`,
    top: `${rect.top}px`,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
    pointerEvents: "auto",
    cursor: "move",
    ...style,
  };

  useSignalEffect(() => {
    if (handleRef.current == null) return;
    return setupDnd(handleRef.current, null, node.config);
  });

  return <Box ref={handleRef} sx={overlayStyle} onClick={(e) => (selected.value = id)}></Box>;
};

const SelectedOverlay = ({ selected, rect, style, node }) => {
  const borderStyle = {
    ...style,
    pointerEvents: "auto",
    zIndex: 1001,
    cursor: "initial",
  };

  const cornerStyle = {
    ...borderStyle,
    background: "white",
  };

  const topLeft = {
    left: rect.left - 4,
    top: rect.top - 4,
    width: 8,
    height: 8,
  };

  const topLeftStyle = {
    ...cornerStyle,
    // cursor: "nw-resize",
  };

  const topRight = {
    left: rect.left + rect.width - 4,
    top: rect.top - 4,
    width: 8,
    height: 8,
  };

  const topRightStyle = {
    ...cornerStyle,
    // cursor: "ne-resize",
  };

  const bottomRight = {
    left: rect.left + rect.width - 4,
    top: rect.top + rect.height - 4,
    width: 8,
    height: 8,
  };

  const bottomRightStyle = {
    ...cornerStyle,
    // cursor: "nw-resize",
  };

  const bottomLeft = {
    left: rect.left - 4,
    top: rect.top + rect.height - 4,
    width: 8,
    height: 8,
  };

  const bottomLeftStyle = {
    ...cornerStyle,
    // cursor: "ne-resize",
  };

  const topRect = {
    ...rect,
    height: 1,
  };
  const topStyle = {
    ...style,
    ...borderStyle,
    border: undefined,
    borderTop: style.border,
    // cursor: "n-resize",
  };

  const leftRect = {
    ...rect,
    left: rect.left,
    width: 2,
  };
  const leftStyle = {
    ...style,
    ...borderStyle,
    border: undefined,
    borderLeft: style.border,
    // cursor: "e-resize",
  };

  const rightRect = {
    ...rect,
    left: rect.left + rect.width - 2,
    width: 2,
  };

  const rightStyle = {
    ...style,
    ...borderStyle,
    border: undefined,
    borderRight: style.border,
    // cursor: "e-resize",
  };

  const bottomRect = {
    ...rect,
    top: rect.top + rect.height - 2,
    height: 2,
  };

  const bottomStyle = {
    ...style,
    ...borderStyle,
    border: undefined,
    borderBottom: style.border,
    // cursor: "n-resize",
  };

  const itemRef = useRef(null);
  const handleStyle = {};

  const flashStyle: any = {
    position: "absolute",
    zIndex: 1005,
  };

  const flashRef = useRef(null);
  useSignalEffect(() => {
    if (selected.value == node?.Id) return;

    function triggerFlash(element, duration = 1000) {
      element?.animate(
        [
          {
            backgroundColor: "#E9F2FF",
            opacity: 0.5,
          },
          {},
        ],
        {
          duration,
          easing: "cubic-bezier(0.25, 0.1, 0.25, 1.0)",
          iterations: 1,
        }
      );
    }
    setTimeout(() => {
      triggerFlash(flashRef.current);
    }, 0);
  });

  return (
    <div ref={itemRef}>
      <BorderOverlay rect={topRect} style={topStyle} />
      <BorderOverlay rect={rightRect} style={rightStyle} />
      <BorderOverlay rect={bottomRect} style={bottomStyle} />
      <BorderOverlay rect={leftRect} style={leftStyle} />

      <BorderOverlay rect={topLeft} style={topLeftStyle} />
      <BorderOverlay rect={topRight} style={topRightStyle} />
      <BorderOverlay rect={bottomRight} style={bottomRightStyle} />
      <BorderOverlay rect={bottomLeft} style={bottomLeftStyle} />

      <BorderOverlay rect={rect} style={flashStyle} ref={flashRef} />

      <HandleOverlay rect={rect} node={node} style={handleStyle} selected={selected} />
    </div>
  );
};

const HandleOverlay = ({ rect, node, style, selected }) => {
  const iconMap = {
    Reference: "\u25C7",
    Component: "\u25C6",
    SimpleContainer: "\u25FB",
    TabContainer: "\u25FB",
  };
  const icon = "\u2630";

  const handleStyle = {
    position: "absolute",
    background: "var(--editor-select-color)",
    display: "flex",
    left: `${rect.left}px`,
    top: `${rect.top - 16}px`,
    width: `auto`,
    height: "16px",
    color: "white",
    lineHeight: "16px",
    fontSize: "12px",
    padding: "0 2px",
    pointerEvents: "auto",
    cursor: "move",
    gap: "0.5rem",
    ...style,
  };

  const reorderStyle = { cursor: "pointer" };

  const handleRef = useRef(null);

  useSignalEffect(() => {
    const id = selected.value;
    const selectedNode = meta.getNode(id);
    if (selectedNode == null) return;
    return setupDnd(handleRef.current, null, selectedNode.config);
  });

  return (
    <Box sx={handleStyle}>
      {node?.operations?.canDrag && (
        <span ref={handleRef} title="Drag">
          {icon}
        </span>
      )}
      <span
        style={reorderStyle}
        title="Select Parent"
        onClick={(e) => {
          selected.value = node.parent;
          e.stopPropagation();
        }}
      >
        {"\u21E7"}
      </span>
    </Box>
  );
};

interface BorderOverlayProps {
  rect: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
  style?: CSSProperties;
}

const BorderOverlay = forwardRef(function BorderOverlay({ rect, style }: BorderOverlayProps, ref) {
  const overlayStyle = {
    position: "absolute",
    left: `${rect.left}px`,
    top: `${rect.top}px`,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
    pointerEvents: "none",
    ...style,
  };

  return <Box sx={overlayStyle} ref={ref}></Box>;
});

const GuideLineOverlay = ({ position, length, orientation }) => {
  const overlayStyle: any = {
    position: "absolute",
    left: orientation === "vertical" ? `${position}px` : undefined,
    top: orientation === "horizontal" ? `${position}px` : undefined,
    width: orientation === "horizontal" ? `${length}px` : "1px",
    height: orientation === "vertical" ? `${length}px` : "1px",
    backgroundColor: "red",
    pointerEvents: "none",
  };

  return <div style={overlayStyle} />;
};

function setupDndForAll(dragOverlays) {
  const entries = meta.getAll();
  const elements = document.querySelectorAll("[data-testid]");
  const elementMap = keyBy(elements, "dataset.testid");
  const cleanupFns: CleanupFn[] = [];

  const newOverlays: BorderOverlay[] = [];

  let pageComponent: ItemNode | null = null;
  for (const [k, v] of entries) {

    if (v.__typename == "Page" && (v.Id === "component-editor" || v.Id === "page-editor")) {
      // get page component config
      pageComponent = v;
      continue;
    }

    if (pageComponent?.config) {
      // check for existing element within Page component
      // Doing that to avoid adding overlay for elements that are not part of the page component (within references for example)
      const existEl = findMeta(pageComponent.config, v.Id);
      if (!existEl) continue;
    }

    const el = elementMap[k];
    if (el == null) {
      // console.log("no dom for", k, v);
      continue;
    }

    const newOverlay = createBorderOverlay(el, k, "drag", v);
    newOverlays.push(newOverlay);

    // cleanupFns.push(setupDnd(el, null, v.config));
  }

  dragOverlays.value = newOverlays;
  // console.log("setupDndForAll", newOverlays);

  return combine(...cleanupFns);
}

export default function NGDesignOverlay({ config, context }) {
  const handlers = setupHandlers(config, context);
  const { selected } = useDesignerForOverlay(config, context, handlers);

  const hoverTarget: Signal<string | null> = useSignal(null);
  const hoverOverlays: Signal<BorderOverlay[]> = useSignal([]);
  const selectedOverlay: Signal<BorderOverlay | null> = useSignal(null);
  const dropOverlays: Signal<any[]> = useSignal([]);
  const dragOverlays: Signal<any[]> = useSignal([]);
  const showDragOverlays: Signal<boolean> = useSignal(true);

  function createSelectedOverlay(id) {
    if (isNil(id)) {
      selectedOverlay.value = null;
    } else {
      const element = document.querySelector(`[data-testid='${id}']`);
      const node = meta.getNode(id);
      const newOverlay = createBorderOverlay(element, id, "selected", node);

      // Add parent overlay to current selection so handles can be drawn
      const parentNode = meta.getNode(node?.parent);
      if (parentNode?.__typename.includes("Container")) {
        const parent = element?.closest(`[data-id='${node?.parent}']`);
        if (!isNil(parent)) {
          const parentOverlay = createBorderOverlay(parent, parentNode.Id, "parent", parentNode);
          newOverlay.parent = parentOverlay;
        }
      }
      selectedOverlay.value = newOverlay;
    }
  }

  const findTarget = (el): { target: HTMLElement | null; id: string | null; node: ItemNode | null } => {
    let target = el?.closest("[data-testid]");
    if (target == null) return { target: null, id: null, node: null };

    let id = target.dataset.testid;
    let node = meta.getNode(id);
    // console.log("target", target, target.dataset, node);
    let i = 0;
    while (node == null) {
      target = target.parentElement.closest(`[data-testid]`);
      id = target?.dataset.testid;
      node = meta.getNode(id);
      if (++i > 100) {
        console.error("couldn't find parent", el);
        return { target: null, id: null, node: null };
      }
    }

    return { target, id, node };
  };

  function createHoverOverlays(el) {
    const { target, id, node } = findTarget(el);
    if (node == null || target == null) return;

    if (hoverTarget.value == id) return;

    // console.log("new target", target, id, node);
    hoverTarget.value = id;

    const newOverlays: BorderOverlay[] = [];
    const primary = createBorderOverlay(target, id, "target", node);
    newOverlays.push(primary);

    const parentNode = meta.getNode(node.parent);
    const parent = target.closest(`[data-testid='${node.parent}']`);
    if (!isNil(parent)) {
      const parentOverlay = createBorderOverlay(parent, parentNode?.Id, "parent", parentNode);
      newOverlays.push(parentOverlay);
    }

    const children = target.querySelectorAll(":scope [data-testid]");
    children.forEach((child) => {
      const childId = child?.dataset?.testid;
      const childNode = meta.getNode(childId);
      if (childNode?.parent != id) return true;
      const secondary = createBorderOverlay(child, childId, "child", childNode);
      newOverlays.push(secondary);
    });

    hoverOverlays.value = newOverlays;
  }

  function clearAll() {
    batch(() => {
      hoverOverlays.value = [];
      hoverTarget.value = null;
      dropOverlays.value = [];
      dragOverlays.value = [];
    });
  }

  const createDropOverlays = ({ location }) => {
    dropOverlays.value = location.current.dropTargets.slice(0, 1).map((x, i) => {
      const rect = x.element.getBoundingClientRect();
      let instruction = isComponent(x.data)
        ? { type: "make-child", currentLevel: 0, indentPerLevel: 0 }
        : extractInstruction(x.data);

      let edge = extractClosestEdge(x.data);

      if (instruction.type === "instruction-blocked" && edge != null) {
        instruction = null;
      } else if (instruction?.type == "make-child") {
        edge = null;
      }

      const style = {
        position: "absolute",
        left: `${rect.left}px`,
        top: `${rect.top}px`,
        width: `${rect.width}px`,
        height: `${rect.height}px`,
        pointerEvents: "none",
        border: "dotted 1px var(--editor-select-color)",
      };

      const overlay = { id: x.data.Id, instruction, edge, style };
      return overlay;
    });
    // log.info(tag, "target change", location.current.dropTargets, dropOverlays.value);;
  };

  useSignalEffect(() => {
    createSelectedOverlay(selected.value);
  });

  useSignalEffect(() => {
    // const handleClick = (e) => {
    //   const { id } = findTarget(e.target);
    //   selected.value = id;
    // };

    const handleMouseOver = (e) => {
      createHoverOverlays(e.target);
    };

    const handleMouseLeave = (e) => {
      batch(() => {
        hoverOverlays.value = [];
      });
    };

    const handleMouseDown = (e) => {
      setTimeout(() => {
        meta.version.value += 1; // triggers redraw on mouse down
      }, 100); // this delay is to ensure we redraw after the click event has been processed for other events (such as a grid resizing)
    };

    const handleKeyUp = (e) => {
      if (e.key === "Alt" || ((e.ctrlKey || e.metaKey) && e.key === "v")) {
        showDragOverlays.value = true; // Reset to true when key is released
        window.postMessage({ Source: "ng", Type: "setInteractionMode", Value: false }, "*");

        if (e.key === "v") {
          // Paste
          window.postMessage({ Source: "ng", Type: "UpdatePage", Value: null }, "*");
        }
      }
    };

    const handleKeyDown = (e) => {
      if (e.altKey) {
        showDragOverlays.value = false;
        window.postMessage({ Source: "ng", Type: "setInteractionMode", Value: true }, "*");
      }
    };

    const handleMessage = (e) => {
      if (e.data.Source === "ng" && e.data.Type === "setInteractionMode") {
        showDragOverlays.value = !e.data.Value;
      }
    };

    document.addEventListener("mouseover", handleMouseOver);
    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseleave", handleMouseLeave);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);
    window.addEventListener("message", handleMessage);

    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseleave", handleMouseLeave);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("message", handleMessage);
    };
  });

  useSignalEffect(() => {
    const pageEl = document.querySelector(`[data-testid='${config.Id}']`);

    const mutationObserver = new MutationObserver((mutationsList) => {
      let changed = false;
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          for (const newNode of mutation.addedNodes) {
            const id = (newNode as any).dataset?.testid;
            if (id != null) {
              changed = true;
            }
          }
        }
      }

      if (changed) {
        meta.version.value += 1;
      }
    });

    mutationObserver.observe(pageEl, {
      // attributes: true, // checks if attributes changed on an HTML node, expensive operation
      childList: true, // similar to below
      subtree: true, // covers checking for if new HTML was added
    });

    const resizeObserver = new ResizeObserver(() => {
      meta.version.value += 1;
    });

    resizeObserver.observe(pageEl);

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  });

  useSignalEffect(() => {
    const ignore = meta.version.value;
    const ignore2 = showDragOverlays.value;

    selectedOverlay.value = null;
    hoverOverlays.value = [];
    const prevHover = hoverTarget.peek();
    hoverTarget.value = null;

    const redrawOverlay = () => {
      createSelectedOverlay(selected.peek());
      const el = document.querySelector(`[data-testid='${prevHover}']`);
      createHoverOverlays(el);
      return setupDndForAll(dragOverlays);
    };

    return runNextFrame(redrawOverlay);
  });

  useSignalEffect(() => {
    return combine(
      monitorForElements({
        onDragStart: (args) => {
          batch(() => {
            hoverOverlays.value = [];
            hoverTarget.value = null;
            createDropOverlays(args);
          });
        },
        onDrag: createDropOverlays,
        onDrop: clearAll,
      }),
      monitorForExternal({
        onDragStart: (args) => {
          meta.version.value += 1;
        },
        onDrag: createDropOverlays,
        onDrop: clearAll,
      })
    );
  });

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1000,
    width: "100%",
    height: "100vh",
    pointerEvents: "none",
  };

  return (
    <>
      <Box className="design-overlay" sx={overlayStyle}>
        {hoverOverlays.value.map((overlay, index) => {
          switch (overlay.type) {
            case "border":
              return <BorderOverlay key={index} {...overlay} />;
            // case "guideline":
            //   return <GuideLineOverlay key={index} {...overlay} />;
            default:
              return null;
          }
        })}
        {dropOverlays.value.map((overlay, index) => (
          <DropOverlay key={index} {...overlay} />
        ))}
        {showDragOverlays.value &&
          dragOverlays.value.map((overlay, index) => <DragOverlay key={index} selected={selected} {...overlay} />)}
        {selectedOverlay.value && <SelectedOverlay selected={selected} {...selectedOverlay.value} />}
        <Box
          sx={{
            ...overlayStyle,
            border: !showDragOverlays.value ? "3px dashed #5a8fc3" : "none",
          }}
        />
      </Box>
    </>
  );
}
