import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isNil } from "lodash-es";
import { computed, ReadonlySignal, signal, Signal, useSignal } from "@preact/signals-react";
import { Box } from "@mui/material";
import {
  getScope,
  getState,
  isSimpleValue,
  meta,
  setupLocalState,
  shouldReturnSignal,
  updateItemContext,
} from "../library/dataService";
import { INGReferenceProps, RuntimeContext } from "../library/NGFieldExtensions";
import { Component, LayoutItem, Reference } from "../../resolvers-types";
import NGLayoutItem from "./NGLayoutItem";
import { log } from "../library/logger";
import { getAst, getExprValue } from "../library/interpreter";
import { GetComponent } from "../library/components";
import { renderModalPopups } from "../components/ComponentUtils";
import { isNullOrEmpty, isVisible, getClassName, getsxObject } from "../library/utils";
import { getTypename } from "../library/metadataUtils";

const tag = "NGReference";

async function retrieveReferenceMetadata(id, refId: string, refType: string, context: RuntimeContext) {
  switch (refType) {
    case "Component": {
      const c = await GetComponent(refId);

      if (isNil(c)) log.error(tag, `Component '${refId}' not found for Reference '${id}'`);

      if (c != null && isNullOrEmpty(getTypename(c))) c.__typename = "Component"; // This should not be necessary once issue with project.save is resolved

      return c;
    }
    default:
      throw new Error(`Reference type ${refType} not implemented`);
  }
}

export default function NGReference({ config, context }: INGReferenceProps) {
  const currentContext = updateItemContext(context, config);
  const location = useLocation();

  const local = setupLocalState(
    config,
    {
      Inputs: useSignal(config.Inputs ?? {}),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? []),
      Style: useSignal(config.Style ?? {}),
      ReferenceId: useSignal(config.ReferenceId),
      ReferenceSource: useSignal(config.ReferenceSource ?? null),
      ReferenceType: useSignal(config.ReferenceType),
    },
    currentContext
  );

  const componentFromReference = useSignal<Component | null>(null);
  const contextFromReference = useSignal<RuntimeContext | null>(null);

  // using useEffect in order to re-run based on location to update the component's state
  useEffect(() => {
    const inputs = local.Inputs.value;
    const refId = local.ReferenceId.value;
    const refType = local.ReferenceType.value;
    const fetchComponents = async () => {
      log.info(tag, "useSignalEffect", config);
      // await delay(1000);

      retrieveReferenceMetadata(config.Id, refId, refType, currentContext).then((c) => {
        if (c == null) return;
        //const inputs = (config as Reference).Inputs;
        if (!isNil(inputs)) {
          const childContext = updateItemContext(currentContext, c);

          const { state, form, parentState } = getState(childContext);
          const scope = getScope(childContext, config, state, form, {}, parentState);

          Object.entries(inputs).forEach(([k, v]) => {
            if (isNullOrEmpty(v as any)) return;

            if (typeof v !== "string") {
              console.error("Invalid input value: '" + v + "' has to be a string but it is a " + typeof v + "instead");
              return;
            }

            const ast: any = getAst(v);
            const returnSignal = shouldReturnSignal(ast);

            let src: Signal | ReadonlySignal | null = null;

            if (returnSignal) {
              src = getExprValue(v as string, scope, null, returnSignal);
            } else if (isSimpleValue(ast)) {
              src = signal(getExprValue(v as string, scope, null, returnSignal));
            } else {
              src = computed(() => getExprValue(v as string, scope, null));
            }

            //const src = getExprValue(v as string, scope, null, returnSignal);
            state[k] = src;
          });
        }

        componentFromReference.value = { ...c };
        contextFromReference.value = updateItemContext(currentContext, { ...c });
        // Trigger meta version change in next rendering cycle.
        // This ensures that any listeners that are doing dom lookups from meta will get correctly sized elements
        // E.g. designer overlay
        setTimeout(() => {
          meta.version.value = meta.version.peek() + 1;
        }, 0);

        log.info(tag, "retrieveReferenceMetadata", c);
      });
    };
    fetchComponents();

    // only re-run when inputs change or location changes
  }, [
    location,
    local.Inputs.value,
    local.ReferenceId.value,
    local.ReferenceType.value,
    // config,
    // currentContext,
    // componentFromReference,
    // contextFromReference,
  ]);

  return (
    <>
      {isVisible(local.Visible.value, config, currentContext) && (
        <>
          <Box
            data-testid={config.Id}
            data-type={config.__typename}
            className={getClassName(local.Classes)}
            sx={getsxObject(local.Style.value)}
          >
            {componentFromReference.value && contextFromReference.value && (
              <>
                {renderModalPopups(componentFromReference.value, contextFromReference.value)}
                <NGLayoutItem
                  context={contextFromReference.value}
                  config={componentFromReference.value as LayoutItem}
                />
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
}
