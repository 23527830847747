import { INGTimePickerProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { signal, useSignal } from "@preact/signals-react";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function NGTimePicker({ config, context }: INGTimePickerProps) {
  const local = setupLocalState(
    config,
    {
      Disabled: useSignal(config.Disabled ?? false),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Value: useSignal(config.Value ?? null),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  // Check localization in total

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        value={local.Value.value ? dayjs(local.Value.value, "HH:mm:ss") : null}
        label={config.Label}
        slotProps={{
          field: { clearable: true, onClear: () => (local.Value.value = null) },
        }}
        {...handlers}
        disabled={local.Disabled.value}
        onChange={(newValue) => {
          local.Value.value = newValue ? dayjs(newValue).format("HH:mm:ss") : null;

          if (handlers["onChange"]) handlers["onChange"](newValue);
        }}
      />
    </LocalizationProvider>
  );
}
