import * as graphql from "../../../resolvers-types";

export const aggregationMethods = [
  { Name: "Count", Value: "count" },
  { Name: "Sum", Value: "sum" },
  { Name: "Average", Value: "average" },
  { Name: "Min", Value: "min" },
  { Name: "Max", Value: "max" },
];


export const odataOperators = [
  { Name: "Equal", Value: "eq", Symbol: "=" },
  { Name: "Not Equal", Value: "ne", Symbol: "!=" },
  { Name: "Greater Than", Value: "gt", Symbol: ">" },
  { Name: "Greater Than or Equal", Value: "ge", Symbol: ">=" },
  { Name: "Less Than", Value: "lt", Symbol: "<" },
  { Name: "Less Than or Equal", Value: "le", Symbol: "<=" },
  // { Name: "And", Value: "and" },
  // { Name: "Or", Value: "or" },
  // { Name: "Not", Value: "not" },
  // { Name: "Add", Value: "add" },
  // { Name: "Subtract", Value: "sub" },
  // { Name: "Multiply", Value: "mul" },
  // { Name: "Divide", Value: "div" },
  // { Name: "Modulo", Value: "mod" },
  // { Name: "Has", Value: "has" },
  // { Name: "In", Value: "in" },
  { Name: "Contains", Value: "contains", Symbol: "contains" },
  { Name: "Starts With", Value: "startswith", Symbol: "startswith" },
  { Name: "Ends With", Value: "endswith", Symbol: "endswith" },
  // { Name: "Length", Value: "length" },
  // { Name: "Index Of", Value: "indexof" },
  // { Name: "Substring", Value: "substring" },
  // { Name: "To Lower", Value: "tolower" },
  // { Name: "To Upper", Value: "toupper" },
  // { Name: "Trim", Value: "trim" },
  // { Name: "Concat", Value: "concat" },
  // { Name: "Year", Value: "year" },
  // { Name: "Month", Value: "month" },
  // { Name: "Day", Value: "day" },
  // { Name: "Hour", Value: "hour" },
  // { Name: "Minute", Value: "minute" },
  // { Name: "Second", Value: "second" },
  // { Name: "Fractional Seconds", Value: "fractionalseconds" },
  // { Name: "Date", Value: "date" },
  // { Name: "Time", Value: "time" },
  // { Name: "Total Offset Minutes", Value: "totaloffsetminutes" },
  // { Name: "Now", Value: "now" },
  // { Name: "Min Date Time", Value: "mindatetime" },
  // { Name: "Max Date Time", Value: "maxdatetime" },
  // { Name: "Floor", Value: "floor" },
  // { Name: "Ceiling", Value: "ceiling" },
  // { Name: "Round", Value: "round" },
  // { Name: "Cast", Value: "cast" },
  // { Name: "Is Of", Value: "isof" },
  // { Name: "Geo Distance", Value: "geo.distance" },
];
export const ODataQueryForm: graphql.Form = {
  __typename: "Form",
  Id: "ODataRequestId",
  FormId: "ODataRequestForm",
  UniqueName: "ODataRequestForm",
  Items: [
    {
      __typename: "Label",
      Id: "odataQueryLabelSelect",
      Sequence: 3,
      DefaultValue: "$select",
    },
    {
      __typename: "MultiSelect",
      Id: "odataQuerySelect",
      Name: "$select",
      Multiple: true,
      Bindings: {
        MultiSelectPossibleValues: "State.FieldsAsProperties.FieldList.fields",
      },
      LabelExpression: "name",
      ValueExpression: "name",
      CheckboxMultiSelect: true,
    },
    {
      __typename: "Label",
      Id: "odataQueryLabelFilter",
      Sequence: 3,
      DefaultValue: "$filter",
    },
    {
      __typename: "InputField",
      Id: "odataQueryFilter",
      Multiline: true,
      MultilineAutosize: true,
      MinRows: "3",
      Name: "$filter",
      Sequence: 4,
    },
    {
      __typename: "Label",
      Id: "odataQueryLabelApply",
      Sequence: 3,
      DefaultValue: "$apply",
    },
    {
      __typename: "InputField",
      Id: "odataQueryApply",
      Multiline: true,
      MultilineAutosize: true,
      MinRows: "3",
      Name: "$apply",
      Sequence: 4,
    },
    {
      __typename: "Label",
      Id: "odataQueryLabelOrderBy",
      Sequence: 3,
      DefaultValue: "$orderby",
    },
    {
      __typename: "InputField",
      Id: "odataQueryOrderBy",
      Name: "$orderby",
      Sequence: 4,
    },
    {
      __typename: "Label",
      Id: "odataQueryLabelTop",
      Sequence: 3,
      DefaultValue: "$top",
    },
    {
      __typename: "InputField",
      Id: "odataQueryTop",
      Name: "$top",
      Sequence: 4,
    },
    {
      __typename: "Label",
      Id: "odataQueryLabelSkip",
      Sequence: 3,
      DefaultValue: "$skip",
    },
    {
      __typename: "InputField",
      Id: "odataQuerySkip",
      Name: "$skip",
      Sequence: 4,
    },
    {
      __typename: "Label",
      Id: "odataQueryLabelCount",
      Sequence: 3,
      DefaultValue: "$count",
    },
    {
      __typename: "InputField",
      Id: "odataQueryCount",
      Name: "$count",
      Sequence: 4,
    },
  ],
  Layout: {
    __typename: "TableLayout",
    RowsItems: [
      ["odataQueryLabelSelect", "odataQuerySelect"],
      ["odataQueryLabelFilter", "odataQueryFilter"],
      ["odataQueryLabelApply", "odataQueryApply"],
      ["odataQueryLabelOrderBy", "odataQueryOrderBy"],
      ["odataQueryLabelTop", "odataQueryTop", "odataQueryLabelSkip", "odataQuerySkip", "odataQueryLabelCount", "odataQueryCount"]
    ],
    RowStyle: {
      "& .MuiTableCell-root": {
        border: "none"
      }
    }
  }
}

export const ODataBaseQueryForm: graphql.Form = {
  Typename: "Form",
  Id: "ODataBaseQueryRequestForm",
  UniqueName: "ODataBaseQueryRequestForm",
  Items: [
    {
      __typename: "Label",
      Id: "0xd4288",
      Sequence: 1,
      DefaultValue: "Select",
    },
    {
      __typename: "MultiSelect",
      Name: "Select",
      Id: "SelectField",
      Multiple: true,
      Bindings: {
        MultiSelectPossibleValues: "State.FieldsAsProperties.FieldList.fields",
      },
      LabelExpression: "name",
      ValueExpression: "name",
      CheckboxMultiSelect: true,
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState"
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.BaseQueryFields": "merge(State.BaseQueryFields,{ Select: Event.map(function(item) {item.name}) })",
                    }
                  },
                ],
              }
            ]
          }
        }
      ],
    },
    {
      __typename: "MultiFilter",
      Id: "0xd324431",
      Label: "Filter",
      Bindings: {
        Data: "State.ODataFilter",
        Fields: "State.FieldsAsProperties.FieldList.fields"
      },
      Items: [
        {
          __typename: "MultiSelect",
          Id: "fds890vns",
          Name: "Name",
          Bindings: {
            MultiSelectPossibleValues: "State.FieldsAsProperties.FieldList.fields",
          },
          LabelExpression: "name",
          ValueExpression: "name",
          Style: {
            width: "300px"
          },
        },
        {
          __typename: "MultiSelect",
          Id: "fdsfsddaaafs",
          Name: "Operator",
          MultiSelectPossibleValues: odataOperators,
          LabelExpression: "Name",
          ValueExpression: "Value",
          Style: {
            width: "200px"
          },
        },
        {
          __typename: "InputField",
          Id: "0xdsad4299",
          Name: "Value",
          // Bindings: {
          //   InputType: "getInputType(State.FieldsAsProperties.FieldList.fields, State.ODataFilter, 'Name')",
          // }
        },
      ],
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState"
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.BaseQueryFields": "merge(State.BaseQueryFields,{Filter: Event})",
                    }
                  }
                ],
              }
            ]
          }
        }
      ]
    },
    {
      __typename: "Label",
      Id: "0xd4205fds5",
      Sequence: 5,
      DefaultValue: "Group By",
    },
    {
      __typename: "MultiSelect",
      Id: "fdsfsdfs",
      Name: "groupby",
      Bindings: {
        MultiSelectPossibleValues: "State.FieldsAsProperties.FieldList.fields",
      },
      LabelExpression: "name",
      ValueExpression: "name",
      Multiple: true,
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState"
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.BaseQueryFields": "merge(State.BaseQueryFields,{ GroupBy: Event })",
                    }
                  }
                ],
              }
            ]
          }
        }
      ],
    },
    {
      __typename: "MultiFilter",
      Id: "0xd324432",
      Label: "Order By",
      Bindings: {
        Data: "State.ODataOrderBy",
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState"
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.BaseQueryFields": "merge(State.BaseQueryFields,{OrderBy: Event})",
                    }
                  }
                ],
              }
            ]
          }
        }
      ],
      Items: [
        {
          __typename: "MultiSelect",
          Id: "orderField",
          Name: "Name",
          Bindings: {
            MultiSelectPossibleValues: "State.FieldsAsProperties.FieldList.fields",
          },
          LabelExpression: "name",
          ValueExpression: "name",
          Style: {
            width: "300px"
          },
        },
        {
          __typename: "MultiSelect",
          Id: "orderByOperator",
          Name: "Value",
          MultiSelectPossibleValues: [
            { Name: "Ascending", Value: "asc" },
            { Name: "Descending", Value: "desc" },
          ],
          LabelExpression: "Name",
          ValueExpression: "Value",
          Style: {
            width: "300px"
          },
        },
      ],
    },
    {
      __typename: "MultiFilter",
      Id: "0xd324433",
      Label: "Aggregate",
      Bindings: {
        Data: "State.ODataAggregate",
      },
      Actions: [
        {
          Trigger: "onChange",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState"
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.BaseQueryFields": "merge(State.BaseQueryFields,{ Aggregate: Event })",
                    }
                  }
                ],
              }
            ]
          }
        }
      ],
      Items: [
        {
          __typename: "MultiSelect",
          Id: "aggregateOperator",
          Name: "aggregateOperator",
          MultiSelectPossibleValues: aggregationMethods,
          LabelExpression: "Name",
          ValueExpression: "Value",
          Style: {
            width: "200px"
          },
        },
        {
          __typename: "MultiSelect",
          Id: "aggregateField",
          Name: "aggregateField",
          Bindings: {
            MultiSelectPossibleValues: "State.FieldsAsProperties.FieldList.fields",
          },
          LabelExpression: "name",
          ValueExpression: "name",
          Style: {
            width: "300px"
          },
        },
      ],
    },
  ]
}